(function(app, $) {
	var $cache = {},
		bodyAddedCls = [],
		event = 'click';
	/*
	 * 	Modernizr.touch isn't enought accurate because some browsers could have touch events (like FF) but didn't fire them.
	 *  We could donate 300 ms delay for touchdevices
	 *
	 */

	function initializeCache(options) {
		$cache = {
			document: $(document),
			window: $(window),
			body: $('body'),
			main: $('main'),
			header: options.headerSel ? $(options.headerSel) : $('header'),
			headerMainTop: 'headerTopSel' in options ? $(options.headerTopSel) : $('.js-header_main-top'),
			togglerSel: '.js-toggler',
			promoBanner: $('.js-header-promo-container'),
			includeElement: null,
			promoBannerHeight: 0,
			activeToggle: null,
			loginToggler: $('.js-login_dropdown-title.js-toggler'),
			custTogglerSel: '.js-custom-toggler',
			blockBeforeHeaderSel: '.js-before_header',
			jsRefSortSelectors: '.js-min_refinement_selector:not(.js-main-refinements), .js-min_sortby_selector',
			closableTogglesFilter: '[data-toggle-closeonoutsideclick="yes"]',
			jsWishlistNonauthClass: 'js-wishlist_nonauth',
			breadcrumbsSelector: '.js-breadcrumb-refinement_list',
			countryLinkClass: 'js-country_selection-link',
			dataBodyAttr: 'move-body',
			currDropdownHeight: 0,
			timeDelay: 1,
			slideEffect: 'easeInExpo',
			headerChangeEvent: 'header.change',
			autoCloseTimerId: null,
			hMinimizedCls: 'h-minimized',
			transitionEvent: app.util.getTransEndEvent(),
			selectorAboveClass: 'b-selectors_above',
			disableUpdatePaddingTop: options.disableUpdatePaddingTop || false,
			options : options,
			firstVisitBanner: $('.js-first-visit-banner')
		};

		$cache.originalHeaderMainTopHeight = false;
		$cache.toggler = $($cache.togglerSel);
		$cache.closableTogglesClick = $cache.toggler.filter($cache.closableTogglesFilter);

		var includePromoSeparateHeight =
			$cache.promoBanner.filter(':visible').length && app.preferences.isSeparatePromoBanner && app.device.isMobileView(),
			headerHeight = getHeaderHeight();
		$cache.bodyPaddingTop = parseInt(headerHeight) + (includePromoSeparateHeight ? $cache.promoBanner.outerHeight() : 0);
	}

	function updateBodyPaddingVariation(padding){
		if ($cache.options) {
			if (isExcludedFromHeaderHeight()) {
				if($cache.options.isSlimHeaderOnScroll && $cache.originalHeaderMainTopHeight) {
					padding -= $cache.originalHeaderMainTopHeight;
				}
				else {
					padding -= parseInt($cache.header.find($cache.options.excludeFromHeaderHeight.elementSel).outerHeight());
				}
				
				if ('borderHeightCorrection' in $cache.options.excludeFromHeaderHeight) {
					padding -= $cache.options.excludeFromHeaderHeight.borderHeightCorrection;
				}
			}

			if ('includeToBodyPadding' in $cache.options) {
				if($cache.options.includeToBodyPadding.elementSel !== null){
					var includeElement = $($cache.options.includeToBodyPadding.elementSel);
					if (includeElement.length && includeElement.is(':visible')) {
						$cache.includeElement = includeElement;
						padding += parseInt($cache.includeElement.height());
					}
				}
			}
		}

		return padding;
	}

	function initializeHelpers() {
		//extend jQuery. Add possibility to chain any function
		$.fn.exec = function exec(fn) {
			fn.call(this);
			return this;
		};
		//reset padding only in checkout page
		$cache.bodyPaddingTop = app.page.ns === 'checkout' ? 0 : $cache.bodyPaddingTop;
		updateBodyPaddingTop($cache.bodyPaddingTop);

		$cache.toggler.each(function() {
			calculateDropUpHeight($(this));
		});
	}

	function attachToggler(e, data) {
		var toggler = $(this);
		if (toggler.data('focus') && $(toggler.data('focus')).length > 0) {
			$(toggler.data('focus'))
				.first()
				.focus();
		}

		var eventObj = {},
			$target = $(e.target);
		e.stopPropagation();
		clearTimeout($cache.autoCloseTimerId);

		if ($target.hasClass($cache.jsWishlistNonauthClass) || $target.closest('.' + $cache.jsWishlistNonauthClass).length) {
			eventObj.$this = $cache.loginToggler;
		} else {
			eventObj.$this = $(this);
		}

		eventObj.$toggleElement = $(eventObj.$this.data('slide'));
		eventObj.contenteditable = eventObj.$toggleElement.find("[contenteditable='true']");
		eventObj.contenteditable.data('toggleOut', false);
		eventObj.paddingTop = $cache.bodyPaddingTop;
		eventObj.useHeaderOffset = !app.device.isMobileUserAgent();
		eventObj.$closeElem = !!eventObj.$this.data('close-element')
			? eventObj.$toggleElement.find(eventObj.$this.data('close-element'))
			: '';
		eventObj.autoCloseTimer = eventObj.$this.data('auto-close-timer');
		eventObj.useAutoCloseTimer =
			data && data.useAutoCloseTimer ? data.useAutoCloseTimer : eventObj.$this.data('use-auto-close-timer');
		eventObj.toggleToHeightClass = eventObj.$this.data('toggle-class') || $cache.hMinimizedCls;
		eventObj.toggleElementClass = !!eventObj.$this.data('toggle-elem-class')
			? eventObj.$this.data('toggle-elem-class')
			: '';

		if (data && !eventObj.$toggleElement.hasClass(eventObj.toggleToHeightClass)) {
			calculateDropUpHeight(eventObj.$this);
			if (eventObj.useHeaderOffset) {
				eventObj.paddingTop =
					eventObj.$toggleElement.outerHeight(true) +
					getHeaderMainTopHeight() +
					getFirstVisitBannerHeight() +
					($cache.promoBanner.filter(':visible').length
						? $cache.promoBanner.outerHeight()
						: $cache.promoBannerHeight);
				if ($cache.includeElement) {
					eventObj.paddingTop += parseInt($cache.includeElement.height());
				}
				updateBodyPaddingTop(eventObj.paddingTop, $cache.slideEffect);
			}
			$cache.autoCloseTimerId = setTimerToClose(eventObj);
			return false;
		}
		$cache.activeToggle = eventObj.$this;
		var transitionDelay = handleClosableTogglesEvent(e);
		eventObj.toggleElementClass && eventObj.$this.toggleClass(eventObj.toggleElementClass);

		if (!!eventObj.$this.data('clone')) {
			$(eventObj.$this.data('clone')).trigger(event);
		} else {
			hideSlideAfterAnim(eventObj.$this);

			var oldTransitionDelay = eventObj.$toggleElement.css('transition-delay');
			eventObj.$toggleElement.css('transition-delay', transitionDelay + "ms");
			eventObj.$toggleElement.toggleClass(eventObj.toggleToHeightClass);
			setTimeout(function(){
				eventObj.$toggleElement.css('transition-delay', oldTransitionDelay);
			}, transitionDelay);

			if (
				eventObj.$toggleElement.hasClass(eventObj.toggleToHeightClass) &&
				eventObj.contenteditable.length &&
				app.device.isMobileUserAgent()
			) {
				eventObj.contenteditable.data('toggleOut', true);
				eventObj.contenteditable.blur();
			}

			eventObj.$toggleElement.removeClass($cache.selectorAboveClass);
			if (!app.util.isVisibleFullHeight(eventObj.$toggleElement[0])) {
				eventObj.$toggleElement.addClass($cache.selectorAboveClass);
			}
			$cache.document.trigger('toggler.toggled', eventObj);

			calculateDropUpHeight(eventObj.$this);
			$cache.currDropdownHeight = 0;

			if (eventObj.useHeaderOffset) {
				if (!eventObj.$toggleElement.hasClass(eventObj.toggleToHeightClass)) {
					$cache.currDropdownHeight = eventObj.$this.data('dropupHeight');
					eventObj.paddingTop = $cache.currDropdownHeight + getHeaderMainTopHeight() + getFirstVisitBannerHeight() + getPromoBannerHeight();
				} else {
					eventObj.paddingTop = getHeaderMainTopHeight() + getFirstVisitBannerHeight() + getPromoBannerHeight();
				}

				if ($cache.includeElement) {
					eventObj.paddingTop += parseInt($cache.includeElement.height());
				}
				updateBodyPaddingTop(eventObj.paddingTop, $cache.slideEffect);
			}

			if (eventObj.$toggleElement.hasClass(eventObj.toggleToHeightClass) && eventObj.$this.data('less')) {
				eventObj.$this.html(eventObj.$this.data('more')).addClass(eventObj.toggleToHeightClass + '-switcher');
			} else {
				eventObj.$this.html(eventObj.$this.data('less')).removeClass(eventObj.toggleToHeightClass + '-switcher');
			}

			if (eventObj.$closeElem.length) {
				eventObj.$closeElem.off(event + '.closeslide').on(event + '.closeslide', closeSlide.bind(eventObj));
				$cache.autoCloseTimerId = setTimerToClose(eventObj);
			}
			if(eventObj.$this.data('toggle-preventdefault')){
				e.preventDefault();
			}
		}

		function closeSlide() {
			this.$toggleElement.toggleClass(this.toggleToHeightClass);
			$cache.document.trigger('toggler.untoggled', { $toggleElement: this.$toggleElement });
			if (this.contenteditable.length && app.device.isMobileUserAgent()) {
				this.contenteditable.data('toggleOut', true);
				this.contenteditable.blur();
			}
			this.$this.toggleClass(this.toggleElementClass);
			$cache.currDropdownHeight = 0;

			function updateSlidePadding() {
				var headerHeight =
					getHeaderMainTopHeight() +
					getFirstVisitBannerHeight() +
					($cache.promoBanner.filter(':visible').length && !isHeaderTransparent()
						? $cache.promoBanner.outerHeight()
						: $cache.promoBannerHeight);

				updateBodyPaddingTop(headerHeight, $cache.slideEffect);
			}

			updateSlidePadding();

			this.$closeElem.off(event + '.closeslide');
			clearTimeout($cache.autoCloseTimerId);
			hideSlideAfterAnim(this.$this);
			return false;
		}

		function setTimerToClose(obj) {
			if (obj.useAutoCloseTimer && obj.autoCloseTimer && +obj.autoCloseTimer && obj.autoCloseTimer !== 'true') {
				return setTimeout(closeSlide.bind(obj), obj.autoCloseTimer);
			}
			return false;
		}
	}

	function attachTogglerOnContainer(container){
		container.find($cache.togglerSel).on(event + ' toggle', function(e, data) {
			attachToggler.call(this, e, data);
		});
	}

	function initializeEvents() {

		$cache.toggler.on(event + ' toggle', function(e, data) {
			if ($(e.target).hasClass($cache.countryLinkClass)){
				return true;
			} else {
				attachToggler.call(this, e, data);
			}
			
		});

		$cache.toggler.on('update.header', function() {
			var headerHeight = getHeaderHeight();
			updateBodyPaddingTop(headerHeight, $cache.slideEffect);
		});
		$cache.header.on('update.header', function(e, timeDely) {
			var headerHeight = getHeaderHeight();
			updateBodyPaddingTop(headerHeight, $cache.slideEffect, timeDely);
			$cache.bodyPaddingTop = parseInt(headerHeight);
		});
		/**
		 * Global click event trigger for closing popups which require to be closed if click happens outside of container
		 */

		$(document).on(event, function(e) {
			handleClosableTogglesEvent(e, true);
		});
		$(document).on('keydown', handleClosableTogglesEvent);
		$(document).on('toggle.hideall', handleClosableTogglesEvent);

		//custom toggler for refinement and sorting selectors
		$cache.document.on(event + ' toggle', $cache.custTogglerSel, function(e) {
			var custToggler = $($cache.custTogglerSel);
			var jsRefSortSelectors = $($cache.jsRefSortSelectors);
			var $this = $(this);
			var toggleElement = $($this.data('slide')),
				bodyTagClass = $this.data('toggle-body-class'),
				hMinimizedClass = $this.data('toggle-class') || $cache.hMinimizedCls,
				hToggledClass = !!$this.data('toggle-elem-class') ? $this.data('toggle-elem-class') : '';
			jsRefSortSelectors.not(toggleElement).addClass(hMinimizedClass);
			custToggler.not($this).removeClass(hToggledClass);
			if (hToggledClass) {
				$this.toggleClass(hToggledClass);
			}
			if (bodyTagClass) {
				$cache.body.toggleClass(bodyTagClass);
				$cache.body.hasClass(bodyTagClass)
					? bodyAddedCls.push(bodyTagClass)
					: bodyAddedCls.splice(bodyAddedCls.indexOf(bodyTagClass), 1);
			}
			toggleElement.toggleClass(hMinimizedClass);

			$this.trigger('toggle.completed');

			// it is necessary to handle click out of container event
			e.stopPropagation();
		});

		$(document).on(event, function(e, extParams) {
			$cache.main.find($cache.custTogglerSel + $cache.closableTogglesFilter).each(function() {
				var $this = $(this);
				var $container = $($this.data('slide')),
					hMinimizedClass = $this.data('toggle-class') || $cache.hMinimizedCls,
					hToggledClass = $this.data('toggle-elem-class') || 'h-toggled';
				if (
					extParams &&
					((extParams.isFilterOpened && $this.data('filterOpened')) ||
						(extParams.isSortByOpened && $this.data('sortbyOpened')))
				) {
					return;
				}
				if ($container.has(e.target).length === 0 && !$container.is(e.target)) {
					// Outside click
					if ($this.hasClass(hToggledClass)) {
						$this.removeClass(hToggledClass);
						$container.addClass(hMinimizedClass);
						$cache.body.removeClass(bodyAddedCls.join(' '));
					}
					// TODO check work with timer when click outside & timer data (auto-close-timer) are present on the element.
				}
			});
		});

		$cache.header.on($cache.headerChangeEvent, function(e, data) {
			if (data !== undefined) {
				$cache.bodyPaddingTop -= data.height;
			}
			updateBodyPaddingTop($cache.bodyPaddingTop + $cache.currDropdownHeight, $cache.slideEffect, $cache.timeDelay);
		});

		if (app.preferences.updateBodyPaddingOnResize) {
			function updateBodyPaddingOnResize() {
				if ((!app.device.isMobileUserAgent() || !$cache.toggler.filter(getEditableToggleSlides).length) && !isExcludedFromHeaderHeight('breadcrumbs')) {
					var breadCrumbs = $($cache.breadcrumbsSelector),
						breadCrumbsHeight = breadCrumbs.length && breadCrumbs.height() > 0 ? breadCrumbs.height() : 0,
						headerHeight = getHeaderHeight();
					$cache.bodyPaddingTop =
						app.page.ns === 'checkout' ? 0 : headerHeight + breadCrumbsHeight - $cache.currDropdownHeight;

					if(app.device.isMobileView() && app.preferences.isSeparatePromoBanner && isPromoBannerVisible()) {
						$cache.header.css('top', $cache.promoBanner.outerHeight() + 'px');
						$cache.bodyPaddingTop += $cache.promoBanner.outerHeight();
					}
					updateBodyPaddingTop($cache.bodyPaddingTop, $cache.slideEffect, $cache.timeDelay);
				}
			}

			if (app.device.currentDevice() === 'desktop') {
				$cache.window.on('resize', updateBodyPaddingOnResize);
			} else if (app.device.currentDevice() === 'tablet' || app.device.currentDevice() === 'mobile') {
				$cache.window.on('orientationchange', updateBodyPaddingOnResize);
			}
		}

		$cache.header.one('onscroll.updating', function() {
			$cache.originalHeaderMainTopHeight = getHeaderMainTopHeight(true);
		});

		$cache.document.on('suggestions.updated', function(data) {
			$cache.currDropdownHeight -= data.height;
		});

		$cache.document.on('refinements-update', function() {
			$cache.body.removeClass(bodyAddedCls.join(' '));
			$($cache.custTogglerSel).each(function() {
				var $this = $(this),
					toggledCls = $this.data('toggle-elem-class'),
					bodyCls = $this.data('toggle-body-class');
				if (bodyCls) {
					$cache.body.toggleClass(bodyCls, $this.hasClass(toggledCls));
				}
			});
		});
	}

	function handleClosableTogglesEvent(e, checkTarget) {
		var highestTransitionDelay = 0;
		if (e.type === 'keydown' && e.which !== 27) {
			return true;
		}

		var elements;

		if (e.type === 'toggle.hideall') {
			elements = $cache.toggler;
		} else if ($cache.activeToggle && $cache.activeToggle.length) {
			var hToggledClass = $cache.activeToggle.data('toggle-elem-class') || 'h-toggled',
				activeElementClassName = $cache.activeToggle.attr('class');
			if (activeElementClassName.lastIndexOf(hToggledClass) !== -1) {
				activeElementClassName = activeElementClassName.substr(0, activeElementClassName.lastIndexOf(hToggledClass) - 1);
			}

			elements = $cache.closableTogglesClick.filter(function() {
				var $this = $(this),
					currentElementhToggledClass = $this.data('toggle-elem-class') || 'h-toggled',
					currentElementClassName = $this.attr('class');

				if (currentElementClassName.lastIndexOf(currentElementhToggledClass) !== -1) {
					currentElementClassName = currentElementClassName.substr(
						0,
						currentElementClassName.lastIndexOf(currentElementhToggledClass) - 1
					);
				}

				return currentElementClassName !== activeElementClassName;
			});
		} else {
			elements = $cache.closableTogglesClick;
		}

		if (elements) {
			elements.each(function() {
				var $this = $(this),
					$toggleElement = $($this.data('slide')),
					$contenteditable = $toggleElement.find("[contenteditable='true']");
				$contenteditable.data('toggleOut', false);
				if (checkTarget && $(e.target).closest($toggleElement).length) return false;

				!!$this.data('toggle-elem-class') && $this.removeClass($this.data('toggle-elem-class'));
				if (!$toggleElement.hasClass($cache.hMinimizedCls)) {
					if($toggleElement.css('transition-duration') !== "undefined"){
						highestTransitionDelay = Math.max(highestTransitionDelay, getTransitionDurationInMs($toggleElement.css('transition-duration')));
					}

					hideSlideAfterAnim($this);
					$cache.document.trigger('toggler.untoggled', { $toggleElement: $toggleElement });
					if ($contenteditable.length && app.device.isMobileUserAgent()) {
						$contenteditable.data('toggleOut', true);
						$contenteditable.blur();
					}
					if (!app.device.isMobileUserAgent()) {
						var bodyTopPadding = getHeaderMainTopHeight() + getFirstVisitBannerHeight() + getPromoBannerHeight();
						if ($cache.includeElement) {
							bodyTopPadding += parseInt($cache.includeElement.height());
						}
						updateBodyPaddingTop(bodyTopPadding, $cache.slideEffect);
					}
				}
				$toggleElement.addClass($this.data('toggle-class') || $cache.hMinimizedCls);
				$cache.currDropdownHeight = 0;
				clearTimeout($cache.autoCloseTimerId);
			});
		}
		$cache.activeToggle = null;
		return highestTransitionDelay;
	}

	function getHeaderHeight(){
		return !isHeaderTransparent() ? $cache.header.height() : 0;
	}

	function getHeaderMainTopHeight(forceTransparent) {
		if($cache.options.isSlimHeaderOnScroll && $cache.originalHeaderMainTopHeight) {
			return $cache.originalHeaderMainTopHeight;
		}

		var height;

		if(typeof forceTransparent !== 'undefined' && forceTransparent === true){
			if (isExcludedFromHeaderHeight()) {
				height = parseInt($cache.header.find($cache.options.excludeFromHeaderHeight.elementSel).outerHeight());
			}
			else {
				height = parseInt($cache.headerMainTop.outerHeight());
			}
		}
		else {
			height = !isHeaderTransparent() ? parseInt($cache.headerMainTop.outerHeight()) : 0;
		}

		return Number.isInteger(height) ? height : 0;
	}

	function getEditableToggleSlides() {
		var toggler = $(this),
			hMinimizedClass = toggler.data('toggle-class') || $cache.hMinimizedCls,
			toggleSlide = $(toggler.data('slide')),
			textField = toggleSlide.find("[contenteditable='true']");

		//if no contenteditable check for ordinary input
		if (!textField.length) {
			textField = toggleSlide.find("input[type='text']");
		}

		return textField.is(':focus') || textField.data('toggleOut');
	}

	/**
	 * This function is used to get the height size of hidden/non-visible elements.
	 * The element is cloned, showed in far far invisible zone, its height is taken and element is destroyed.
	 */
	function getImitationBlockHeight($toggleElement) {
		var clone,
			blockHeight,
			toggleElWidth = $toggleElement.width();

		clone = $toggleElement
			.clone()
			.css({
				position: 'absolute',
				width: toggleElWidth,
				top: '-10000px',
				left: '-10000px',
				padding: '0',
				margin: '0'
			})
			.appendTo($toggleElement.parent());

		blockHeight = clone.height();
		clone.remove();
		return blockHeight;
	}

	/**
	 * There are we have a bit of magic for measuring height of drop ups.
	 * We need to calculate them for padding-top value of body
	 */
	function calculateDropUpHeight($toggler) {
		var $toggleElement = $($toggler.data('slide')).eq(0),
			toggleToHeightClass = $toggler.data('toggle-class') || $cache.hMinimizedCls,
			hasToggledClass = $toggleElement.hasClass(toggleToHeightClass),
			height = 0;

		if ($toggleElement.length) {
			/**
			 * At first script re-init CSS rendering value for toggles - max-height
			 * and don't have any dependencies from h-minimized because we will have
			 * re-render event because of css-property "max-height: 0 !important"
			 * At second remove h-minimized to calculate height of each toggle
			 */
			hasToggledClass && $toggleElement.removeClass(toggleToHeightClass);
			//move body only if appropriate data attribute is present
			if ($toggler.data($cache.dataBodyAttr)) {
				height = getImitationBlockHeight($toggleElement);
			}
			/**
			 * Nice. We've got height of drop up so let's save it into
			 * data storage of toggle and restore default behavior
			 */
			hasToggledClass && $toggleElement.addClass(toggleToHeightClass).css('max-height', '');
			$toggler.data('dropupHeight', height);
		}
	}

	function updateBodyPaddingTop(paddingTop, slideEffect, timeDelay) {
		if ($cache.disableUpdatePaddingTop) {
			return false;
		}
		paddingTop = updateBodyPaddingVariation(paddingTop);

		var blockBeforeHeader = $($cache.blockBeforeHeaderSel);
		if (blockBeforeHeader.length) {
			paddingTop += parseInt(blockBeforeHeader.height());
		}
		if (slideEffect === undefined) {
			if($cache.options.updateOtherElementsSel) {
				updateOtherElements(paddingTop);
			}
			
			$cache.body.stop(true).animate({ 'padding-top': paddingTop + 'px' }, { done: eventTrigger });
			
		} else if (timeDelay === undefined) {
			if (!(typeof $cache.options.slideEffect !== "undefined" && $cache.options.slideEffect.disableBodyPaddingTop)) {
				$cache.body.stop(true).animate({ 'padding-top': paddingTop + 'px' }, { 'easing ': slideEffect, done: eventTrigger });
			}

			if($cache.options.updateOtherElementsSel) {
				updateOtherElements(paddingTop);
			}
			
		} else {
			if($cache.options.updateOtherElementsSel) {
				updateOtherElements(paddingTop);
			}
			
			$cache.body
				.stop(true)
				.animate(
					{ 'padding-top': paddingTop + 'px' },
					{ duration: timeDelay, 'easing ': slideEffect, done: eventTrigger }
				);
		}
		function eventTrigger() {
			app.components.toggler.custom.bodyAnimationDeferred.resolve();
			$cache.document.trigger('body-padding-updated', { paddingTop: paddingTop });
		}
	}

	// the method changes display property of toggled elements with password field in order to avoid browser warning that connection is not secure
	function hideSlideAfterAnim($toggler) {
		if (!!$toggler.data('hide-slide')) {
			var $slide = $($toggler.data('slide'));
			$slide.show(0);
			$slide.one($cache.transitionEvent, function() {
				if ($(this).hasClass($toggler.data('toggle-class') || $cache.hMinimizedCls)) {
					$(this).hide(0);
				}
			});
		}
	}

	function getTransitionDurationInMs(duration){
		if(typeof duration === "undefined") {
			return 0;
		}

		var time = parseFloat(duration);
		var unit = duration.replace(/[0-9-.]/g, '');
		if(unit === 's'){
			time = time * 1000;
		}
		else if(unit !== 'ms'){
			time = 0;
		}
		return time;
	}
	
	function updateOtherElements(value) {
		
		var paddingTop = value;
		
		if (!isExcludedFromHeaderHeight()) {
			paddingTop -= parseInt($cache.header.find($cache.options.excludeFromHeaderHeight.elementSel).outerHeight());
			if ('borderHeightCorrection' in $cache.options.excludeFromHeaderHeight) {
				paddingTop -= $cache.options.excludeFromHeaderHeight.borderHeightCorrection;
			}
		}

		$($cache.options.updateOtherElementsSel).each(function() {
			var element = $(this).first();
			element.css('padding-top', '');
			var totalPaddingTop = parseInt(element.css('padding-top')) + paddingTop;
			
			element.css('padding-top', totalPaddingTop + 'px');
		})
	}
	
	function isExcludedFromHeaderHeight (searchFor) {
		if (
			'excludeFromHeaderHeight' in $cache.options &&
			(((($cache.options.excludeFromHeaderHeight.hasOwnProperty("excludeFromAllPages") && $cache.options.excludeFromHeaderHeight.excludeFromAllPages) 
					|| $cache.options.excludeFromHeaderHeight.pages
					&& $cache.options.excludeFromHeaderHeight.pages.indexOf(app.page.currentPage) !== -1)
					&& $cache.header.find($cache.options.excludeFromHeaderHeight.elementSel).length)
			|| searchFor && $cache.options[searchFor])
		) {
			return true;
		}
		
		return false;
	}
	
	function isHeaderTransparent(){
		return $cache.options.countTransparentHeader && $cache.header.data('headerTransparent');
	}
	
	function getFirstVisitBannerHeight() {
		if (parseInt($cache.firstVisitBanner.css('bottom')) < parseInt($cache.firstVisitBanner.css('top'))) {
			return 0;
		}

		return $cache.firstVisitBanner.filter(':visible').length ? $cache.firstVisitBanner.outerHeight() : 0;
	}
	
	function getPromoBannerHeight() {
		return isPromoBannerVisible() ? $cache.promoBanner.outerHeight() : $cache.promoBannerHeight;
	}
	
	function isPromoBannerVisible() {
		return $cache.promoBanner.filter(':visible').length && !$($cache.blockBeforeHeaderSel).has($cache.promoBanner).length;
	}

	/*************** app.components.toggler.custom public object ***************/
	app.components = app.components || {};
	app.components.toggler = app.components.toggler || {};
	app.components.toggler.custom = {
		// initializations
		init: function(options) {
			initializeCache(options);
			initializeEvents();

			if (app.device.isMobileView() && app.preferences.isMobileStickyHeader) {
				$cache.document.on('body.changed', function() {
					$cache.body.removeAttr('style');
					$cache.body = $('.js-site_wrapper');
					$cache.bodyPaddingTop = getHeaderHeight();

					initializeHelpers();
				});
			}
			else {
				initializeHelpers();
			}
		},

		bodyAnimationDeferred: new $.Deferred(),

		attachTogglerOnContainer: attachTogglerOnContainer,
		updateBodyPaddingTop: updateBodyPaddingTop
	};
})((window.app = window.app || {}), jQuery);
