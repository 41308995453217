;(function (app, $) {

	$cache = {};
	if (app.device.isMobileView()) {
		var $cache = {
			body : $('body'),
			swipeContOpenMenuSel : '.m-site_opened .js-header_menu_toggle'
		}
		$cache.body.on('swipeleft', $cache.swipeContOpenMenuSel, app.components.global.categoriesNavigation.menuToggle);
	};
	$cache.owlCarouselRecomClass = 'js-owl_carousel_recom';
	
	/**
	 * @function
	 * @description Append part of suggested phrase 
	 * @param {Array} Array of searched product + suggested phrase
	 * @param {Object} global cache
	 */
	var placeSuggest = function (data, $cache) {
		if (!data || data.length == 0){ 
			$cache.suggestInput.empty();
			return;
		}
		var firstElem = data[0],
			searchTerm = $cache.searchInput.val();
		
		if ("suggest" in firstElem){
			var suggestPhrase = firstElem.suggest;
			
			suggestPhrase = suggestPhrase.substring(searchTerm.length, suggestPhrase.length);
			$cache.suggestInput.html(suggestPhrase);
		}
	}
	
	app.recommendations.initCarouselAfterAjaxLoad = function($carouselContainer){
		if ($carouselContainer) {
			$($carouselContainer).each(function(){
				var $this = $(this);
				if ($this.hasClass($cache.owlCarouselRecomClass) && !$this.data('owlCarousel')) {
					app.owlcarousel.initCarousel($this);
				}
			})
		};
	}
	
	$(document).on('recommendations.loaded', function(e, block){
		$container = $(block).find('.'+$cache.owlCarouselRecomClass);
		app.recommendations.initCarouselAfterAjaxLoad($container);
	});
	
	app.recommendations.initCarouselAfterAjaxLoad($('.'+$cache.owlCarouselRecomClass));
	
	/**
	 * @function
	 * @description callback functions that are using in DG brand 
	 */
	app.simpleSearchFunctions = {
		simpleSearchHandler : function ($cache) {
			isLoading = true;
			$.extend($cache, {
				selectedCategory: $('.js-min_search .js-simple_search_category_button.active').val() || app.preferences.simpleSearchDefaultCategoryId
			});

			var that = this,
				searchTerm = $cache.searchInput.val(),
				url = app.util.appendParamsToUrl(app.urls.searchSuggestions, { cgid: $cache.selectedCategory, q: searchTerm });
				
			app.ajax.load({
				url: url,
				callback : function (data) {
					that.successSimpleSearchCallback(data, $cache);
				}
			});

		},
		
		/**
		 * @function
		 * @description Append part of suggested phrase 
		 * @param {Array} Array of searched product + suggested phrase
		 * @param {Object} global cache
		 */		
		successSimpleSearchCallback : function(data, $cache) {
			this.buildResponseDom(data, $cache);
			placeSuggest(data, $cache);
			if($cache.resultContainer.find('li').length){
				$cache.resultContainer.removeClass(this.minimizedClass);
			};
			setTimeout(function() { 
				isLoading = false;				

				var bodyPaddingTop = parseInt($("header").height());
				$('body').animate({"padding-top": bodyPaddingTop + "px"}, 1000, "easeInExpo");
			
			}, this.delay);
			
		}
	}
	
}(window.app = window.app || {}, jQuery));