(function(app, $) {
	function PopupsMgr() {
		this.openedPopups = [];
	}
	PopupsMgr.prototype.open = function(id, params) {
		if (id in app.popups) {
			app.popups[id].open(params);
			this.openedPopups.push(app.popups[id]);
		}
	};
	PopupsMgr.prototype.close = function(id) {
		if (id in app.popups) {
			app.popups[id].close();
		}
	};
	PopupsMgr.prototype.closeAll = function() {
		for (var i = 0, len = this.openedPopups.length; i < len; i++) {
			this.openedPopups[i].close();
		}
	};
	PopupsMgr.prototype.popupIsClosed = function(popup) {
		if (this.openedPopups.indexOf(popup) !== -1) {
			this.openedPopups.splice(this.openedPopups.indexOf(popup), 1);
		}
	};
	app.popupsMgr = new PopupsMgr();
})((window.app = window.app || {}), jQuery);
