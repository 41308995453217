/**
 * Components specific config
 * */
(function(app) {
	var componentsConfig = {
		global: {
			components: {
				'global.toggler': { enabled: false },
				'simplesearch.gender': {},
				'global.navigation': { adjustLeftNavHeightOnResize: true },
				'global.simplesearch': {
					simpleSearchHandler: app.simpleSearchFunctions && app.simpleSearchFunctions.simpleSearchHandler,
					successSimpleSearchCallback: app.simpleSearchFunctions && app.simpleSearchFunctions.successSimpleSearchCallback
				},
				'toggler.custom': { headerSel: '.js-header_main' },
				'global.scrollToTop': {},
				'global.producttile': {
					keepHovered: true,
					keepHoverTabletOnly: true,
					productHoverBoxSel: '.js-product_tile'
				},
				'categoryflyout.custom': {},
				'quickview.custom': {},
				'customerservice.custom': {},
				'wishlist.custom': {},
				'account.addresses': { width: '50%', height: 'auto', autoSize: false },
				'account.paymentinstruments': { width: '50%', height: 'auto', autoSize: false },
				'global.sendToFriend': { width: '50%', height: 'auto', autoSize: false },
				'global.firstvisitbanner': { mode: 'simple' },
				'global.newsletter': {
					width: 900,
					height: 'auto',
					padding: 16,
					autoSize: false,
					helpers: {
						overlay: false
					}
				},
				'global.quickview': { quickViewBlockSel: '.js-quickview, .js-hp_quickview' },
				'newsletter.handlepopup': {
					width: 900,
					height: 'auto',
					padding: 16,
					autoSize: false,
					helpers: {
						overlay: false
					}
				},
				'footer.custom': {},
				'storefront.custom': {},
				'global.simplesubscription': {
					submitActionSelector: null,
					width: 900,
					height: 'auto',
					padding: 16,
					autoSize: false,
					helpers: {
						overlay: false
					}
				},
				'social.custom': {}
			}
		},
		search: {
			components: {
				'search.custom': {},
				'search.mobile': {
					updateOnSelect: false,
					attachClassOnBody: 'm-filters-opened'
				},
				'search.filters': { loaderClass: 'refinement-loader' },
				'global.accordion': {}
			}
		},
		storelocator: {
			components: {
				'storelocator.custom': {}
			}
		},
		product: {
			components: {
				'product.custom': {},
				'product.fixedimages': { enabled: true },
				'social.custom': {},
				'global.videoMgr': {
					elementSelector: '.js-videomgr'
				},
				'product.video': {}
			}
		},
		checkout: {
			components: {
				'checkout.custom': {},
				'global.stickykit': {
					recalcOn: 'cart.update.models',
					'.js-cart_table-header': {}
				},
				'simplesearch.gender': { enabled: false }
			}
		},
		giftcertpurchase: {
			components: {
				'giftcertpurchase.custom': {}
			}
		},
		newsletter: {
			components: {
				'account.newsletter': {}
			}
		},
		storefront: {
			components: {
				'homepage.custom': {},
				'storefront.fullpage': {
					enable: true,
					reInitWhileResizeWindow: true,
					fullPageScrollBar: false
				},
				'global.transparentheader': { transparentWhileScrolling: true, onlyHomePage: true },
				'global.videoMgr': {
					elementSelector: '.js-videomgr'
				}
			},
			'global.shopthelook': {
				calcHeightElement: app.preferences.isMobileView,
				showMobileTitle: true,
				fancyboxSettings: {
					closeOpacity: false,
					closeEffect: 'fade',
					closeSpeed: 600,
					closeEasing: 'swing',
					closeMethod: 'zoomOut',
					helpers: {
						title: {
							type: 'inside',
							position: 'top'
						},
						overlay: {
							locked: true
						}
					}
				}
			}
		},
		error: {
			components: {
				'recommendationblock.custom': {}
			}
		}
	};

	/** ************* publish app.components.config.specific************** */
	app.componentsconfig = app.componentsconfig || {};
	app.componentsconfig.specific = componentsConfig;
}(window.app = window.app || {}, jQuery));
