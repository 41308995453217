(function(app, $) {
	var $cache = {};

	function initializeCache() {
		$cache = {
			document: $(document),
			window: $(window),
			body: $('body'),
			header: $('header'),
			mainHeader: $('.js-header_main'),
			headerSelector: 'header',
			headerMainTop: $('.js-header_main-top'),
			headerServiceMenu: $('.js-header_service_menu'),
			headerPanel: $('.js-header-panel'),
			headerTransparent: $('.js-is_header_transparent'),
			headerTransparentClass: 'b-transparent_header',
			headerHoveredClass: 'header-hovered',
			fullWidthBanner: $('.js-owl_carousel-with-thumbs'),
			bannerSection: $('.js-section section.js-scroll_to_me'),
			minicartContainer: $('.js-mini_cart-flyout'),
			minicartContainerSel: '.js-mini_cart-flyout',
			minicartTitle: $('.js-mini_cart-title'),
			minicartQty: $('.b-minicart-quantity_value'),
			leftHeaderNavigation: $('.l-main_navigation'),
			leftHeaderNavigationMobile: '.l-main_navigation',
			wrapperMobile: '.js-site_wrapper',
			menuButton: $('.js-vertical_menu-button'),
			navigationOverlay: $('.js-main_navigation-overlay'),
			tabletHeaderMenuNav: $('.tablet-header-menu-nav'),
			promoBanner: $('.js-header-promo-container'),
			promoBannerHeight: 0,
			closePromoButton: $('.js-header-promo-close'),
			slideEffect: 'easeInExpo',
			headerChangeEvent: 'header.change',
			hMinimizedClass_hHiddenClass: 'h-minimized h-hidden',
			subcatWrapperSel: $('.js-menu_subcategory_wrapper'),
			categorySubItemSel: '.js-menu_category-level_2-item',
			owlCarouselSel: '.js-owl_carousel',
			lastVerticalItemSel: 'b-menu_category-level_2-item--vertical_last',
			promoBannerCookieName: app.preferences.promoBannerCookieName,
			headerIcons: $('.js-search-icon, .js-mini_cart-title'),
			promoHideTimeout: 1 * 60 * 60 * 1000, // Hours * minutes * seconds * milliseconds
			searchFlyoutSel: '.js-min_search',
			searchActiveClass: 'm-search_opened',
			hMinimizedClass: 'h-minimized',
			loginUsernameInputSel: '#dwfrm_login_username',
			loginFlyout: $('.js-login_dropdown')
		};
		$cache.event = {
			headerChange: 'header.change',
			minicartRemove: 'minicart.product.removed',
			minicartShow: 'minicart.show'
		};
	}

	function initializeDOM() {
		adaptFullWidthSliderHeight();
		calculateFlyoutColumnsQuantity();
		initPromoBanner();
		initializeHelpers();
	}

	function initializeHelpers() {
		if (!app.device.isMobileView()) {
			app.components.global.minicart.show = function() {

			};
		} else {
			adaptLeftNavPadding();
		}
	}

	function calculateFlyoutColumnsQuantity() {
		$cache.subcatWrapperSel.each(function() {
			var subcatWrapper = $(this);
			var columnsQuantity = 0;
			var flyoutItems = subcatWrapper.find($cache.categorySubItemSel);
			var columnsVertical;
			var columnsHorizontal;

			columnsVertical = flyoutItems.filter(function() {
				return $(this).attr('class').indexOf('--vertical') !== -1;
			});

			columnsHorizontal = flyoutItems.filter(function() {
				return $(this).attr('class').indexOf('--horizontal') !== -1;
			});

			if (columnsVertical.length) {
				columnsQuantity += 1;
			}

			if (columnsHorizontal.length) {
				columnsQuantity += columnsHorizontal.length;
			}

			// add additional class to last vertical item
			// to align styles with horizontal items layout
			if (columnsVertical.length && columnsHorizontal.length) {
				columnsVertical.last().addClass($cache.lastVerticalItemSel);
			}

			subcatWrapper.addClass('m-' + columnsQuantity + '-columns');
		});
	}

	function adaptFullWidthSliderHeight() {
		var headerHeight = $cache.header.height();
		var windowHeight = $cache.window.height();

		$cache.fullWidthBanner.css('height', (windowHeight - headerHeight) + 'px');
		$cache.bannerSection.css('height', (windowHeight - headerHeight) + 'px');
	}

	function adaptBodyPadding() {
		var isHomePage = app.page && app.page.currentPage && (app.page.currentPage === 'homepage');
		var promoHeight = $cache.promoBanner.hasClass($cache.hMinimizedClass) ? 0 : $cache.promoBanner.innerHeight();
		var hBlock1 = promoHeight + (app.device.currentDevice() === 'mobile' ? $cache.headerServiceMenu.outerHeight() : $cache.headerPanel.outerHeight());
		var hBlock2 = (isHomePage ? hBlock1 : (promoHeight + (app.device.currentDevice() === 'mobile' ? $cache.headerServiceMenu.outerHeight() : $cache.headerMainTop.outerHeight())));

		$cache.body.css('padding-top', hBlock1 + 'px');
		$cache.body[0].style.setProperty('--header-height', $cache.header.height() + 'px');
		$cache.headerTransparent.css('margin-top', -Math.abs(hBlock2));
	}

	function adaptLeftNavPadding() {
		var headerHeight = $cache.header.height();

		$cache.body.find($cache.leftHeaderNavigationMobile, $cache.wrapperMobile).css('padding-top', headerHeight + 'px');
		$cache.body.find($cache.wrapperMobile).css('padding-top', headerHeight + 'px');

		$cache.window.trigger('fullpage.logo.move');
	}

	function initPromoBanner() {
		if (!$.cookie($cache.promoBannerCookieName)) {
			$cache.promoBanner.removeClass($cache.hMinimizedClass_hHiddenClass);
		} else {
			$cache.promoBanner.addClass($cache.hMinimizedClass_hHiddenClass);
		}

		$cache.promoBannerHeight = parseInt($cache.promoBanner.outerHeight());

		$cache.closePromoButton.on('click', function() {
			var expiration = new Date();

			$cache.promoBanner.addClass($cache.hMinimizedClass_hHiddenClass);

			expiration.setTime($cache.promoHideTimeout + expiration.getTime());
			$.cookie($cache.promoBannerCookieName, true, { expires: expiration, path: '/' });

			$cache.header.trigger($cache.event.headerChange, { height: $cache.promoBannerHeight });

			if (app.device.isTouchDevice()) {
				var isHomePage = app.page && app.page.currentPage && (app.page.currentPage === 'homepage');

				if (isHomePage) {
					$cache.header.addClass($cache.headerTransparentClass);
					$cache.header.removeClass($cache.headerHoveredClass);
				}
			}

			adaptBodyPadding();
		});
	}

	function safariLoginPasskeyHandler(e, loginUsernameInput) {
		const eventName = e.type + '.' + e.namespace;

		if (!$cache.loginFlyout.hasClass($cache.hMinimizedClass)) {
			loginUsernameInput.prop('disabled', false);
			$cache.document.off(eventName, safariLoginPasskeyHandler);
		}
	}

	function initializeEvents() {
		const searchFlyout = document.querySelector($cache.searchFlyoutSel);

		if (searchFlyout) {
			const observer = new MutationObserver(function(mutations) {
				mutations.forEach(function(mutation) {
					if (mutation.attributeName === 'class') {
						if (mutation.target.classList.contains($cache.hMinimizedClass)) {
							$cache.body.removeClass($cache.searchActiveClass);
						} else {
							$cache.body.addClass($cache.searchActiveClass);
						}
					}
				});
			});

			observer.observe(searchFlyout, {
				attributes: true
			});
		}

		$cache.headerIcons.on('click', function() {
			$cache.header.trigger('header.change', {
				headerHeight: $cache.header.height()
			});
		});

		if ($cache.mainHeader.length && $cache.mainHeader.css('position') === 'fixed') {
			// eslint-disable-next-line no-undef,no-new
			new ResizeSensor($cache.mainHeader.get(0), function() {
				adaptBodyPadding();

				if (app.device.currentDevice() === 'desktop') {
					adaptFullWidthSliderHeight();
				} else if (app.device.currentDevice() === 'mobile') {
					adaptLeftNavPadding();
				}
			});

			$cache.window.resize(function() {
				adaptFullWidthSliderHeight();
				adaptBodyPadding();

				if (app.device.isMobileView()) {
					adaptLeftNavPadding();
				}
			});
		}

		/**
		 * Event "minicart.show" triggers after adding product to cart
		 * Field 'html' in data object should contain rendered minicart
		 */
		$cache.document.on($cache.event.minicartShow + ' ' + $cache.event.minicartRemove, function(event, data) {
			var content = $(data.html).find($cache.minicartContainerSel);

			$cache.minicartContainer.html(content.html());

			var cartQty = $('.js-cart_qty').text();
			var owlCarouselInside = $cache.minicartContainer.find($cache.owlCarouselSel);

			$cache.minicartQty.text(cartQty ? '(' + cartQty + ')' : '');

			if (cartQty.length) {
				if (owlCarouselInside.length) {
					if (!('silent' in data) || !data.silent) {
						owlCarouselInside.one('owl.afterUpdate', function() {
							$cache.minicartTitle.trigger('click', { useAutoCloseTimer: true });
						});
					}
				} else {
					$cache.minicartTitle.trigger('click', { useAutoCloseTimer: true });
				}

				app.owlcarousel.init();
			} else {
				$cache.document.trigger('toggle.hideall');
				$cache.header.trigger('header.change');
			}

			$cache.document.trigger('minicart.load');
		});

		function hideTabletNavigation() {
			$cache.document.trigger('toggle.hideall');

			$cache.menuButton.toggleClass('m-active');
			$cache.tabletHeaderMenuNav.toggleClass('h-hidden');
			$cache.document.find('.b-menu_category-level_3').toggleClass('h-hidden');

			if ($cache.leftHeaderNavigation.css('display') === 'none') {
				$cache.leftHeaderNavigation.toggleClass('m-active');
				$cache.mainHeader.removeClass($cache.headerTransparentClass);

				if (!app.device.isMobileView()) {
					app.components.global.navigation.adjustLeftNavHeight();
				}
			} else if ($cache.leftHeaderNavigation.css('display') === 'block') {
				$cache.leftHeaderNavigation.toggleClass('m-active');
				$cache.mainHeader.addClass($cache.headerTransparentClass);
				$cache.document.find('.b-menu_category-level_3').removeClass('h-hidden');
			}

			adaptBodyPadding();
		}

		// show|hide tablet menu with categories & customer services|contact links, hide 3rd level categories
		$cache.menuButton.on('click', hideTabletNavigation);
		$cache.navigationOverlay.on('touchend', hideTabletNavigation);

		// navigation menu is closing, if click outside on tablet and mobile
		$cache.document.on('click', hideNavigation);
		$('.js-toggler').on('click', hideNavigation); // to bypass toggler's e.stopPropagation()

		function hideNavigation(e) {
			if (($cache.menuButton.hasClass('m-active')) && !($(e.target).hasClass('l-main_navigation')) && !($(e.target).parents('.l-main_navigation.js-main_navigation.m-active').length || $(e.target).hasClass('js-vertical_menu-button'))) {
				e.preventDefault();
				$cache.menuButton.toggleClass('m-active');
				$cache.tabletHeaderMenuNav.toggleClass('h-hidden');
				$cache.leftHeaderNavigation.toggleClass('m-active');
				$cache.document.find('.b-menu_category-level_3').removeClass('h-hidden');
			} else if (($cache.body.hasClass('m-menu_opened'))
					&& !($(e.target).hasClass('l-main_navigation'))
					&& !($(e.target).parents('.l-main_navigation.js-main_navigation.m-navigation_opened').length || $(e.target).hasClass('js-header_menu_toggle'))
					&& !($(e.target).hasClass('fancybox-close'))
					&& !($(e.target).hasClass('fancybox-wrap'))) {
				app.components.global.categoriesNavigation.menuToggle();
			}
		}

		// Safari passkey autofocus fix
		const loginUsernameInput = $cache.loginFlyout.find($cache.loginUsernameInputSel);

		if (app.device.browser().safari && !app.device.isMobileView() && loginUsernameInput) {
			loginUsernameInput.attr('disabled', 'disabled');
			$cache.document.on('toggler.toggled fakelogin.showpopup', function(e) {
				safariLoginPasskeyHandler(e, loginUsernameInput);
			});
		}
	}

	/** ************* app.components.global.header public object ************** */
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.header = {
		// initializations
		init: function() {
			initializeCache();
			initializeDOM();
			initializeEvents();
		},
		adaptBodyPadding: adaptBodyPadding
	};
})(window.app = window.app || {}, jQuery);
