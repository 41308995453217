( function(app, $) {
	var $cache = {};

	function initializeCache() {
		$cache = {
			document : $(document),
			owlQuickviewSel : ".js-pdp_primary_content_quickview .js-quickview_slider",
			productVarReloadEvent : "product.variation.reloaded",
			variationItem: ".js-variation-item",
			classOpen : 'open',
			variationDropdownSel: '.js-variation-dropdown',
			hidden: 'h-hidden'
		};
	}
	
	function initializeEvents() {
		$cache.document.on('quickview.beforeShow', function(){
			
			var carousel = $(".js-quickview_slider");
			app.owlcarousel.initCarousel(carousel);
			$(".js-owl_carousel_quickview_nav").on('click', function(e) {
				var $this = $(this),
					action = $this.data('nav');
				if(action) {
					carousel.trigger(action + '.owl.carousel', [300]);
				}
			});
		});
		
		$cache.document.on('quickview.opened', function(){
			$cache.document.off('click', $cache.variationItem).on('click', $cache.variationItem, function () {
				$($cache.variationItem).not(this).removeClass($cache.classOpen);
				$($cache.variationItem).not(this).find($cache.variationDropdownSel).addClass($cache.hidden);
				$(this).find($cache.variationDropdownSel).toggleClass($cache.hidden);
				$(this).toggleClass($cache.classOpen);
			});
		});
		
		$cache.document.on($cache.productVarReloadEvent, function(event, data){
			if (data && data.mode === 'quickview') {
				var carousel = $($cache.owlQuickviewSel);
				app.owlcarousel.initCarousel(carousel);
			};
		});
	}
	
	app.components = app.components || {};
	app.components.quickview = app.components.quickview || {};
	app.components.quickview.custom = {

		init : function () {
			initializeCache();
			initializeEvents();
		}
	};
})(window.app = window.app || {}, jQuery);