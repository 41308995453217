(function(app, $) {
	var $cache = {};
	var configs = {
		template: "<a href='{{link}}' target='_blank'><img src='{{image}}' /></a>",
		scroll: {
			speed: 500,
			animate: 'swing'
		}
	};
	var documentEventsInitialized = false;

	function initializeCache(container) {
		$cache = {
			container: container,
			document: $(document),
			footer: $('footer'),
			header: $('.js-header_main'),
			pdpSlidesLink: $('.js-product_slide-link'),
			productImagesCntr: container.find('.js-product_images_container'),
			pdpSlideMenu: $('.js-product_slides-menu'),
			thumbnails: container.find('.js-thumbnails'),
			openCarePopup: $('.js-care_details-popup'),
			thumbnailsCntrSel: '.js-thumbnails_slider',
			popupContent: $('.js-care_details-content'),
			backButton: $('.js-pdp-back_button'),
			carouselImageSel: '.js-producttile_image',
			fancyboxOpen: $('.js-pdp_fancybox_open'),
			needHelpLink: container.find('.js-pdp_need_help_link'),
			needHelpContainer: $('.js-pdp_need_help_link_container'),
			productDetail: $('.js-product_detail'),
			productVariations: $('.js-product_variations'),
			productData: $('.js-product_data'),
			tabsTitle: container.find('.js-product_tabs-title'),
			tabs: $('.js-product_tabs'),
			share: container.find('.js-product-share-text'),
			sizeWrapper: $('.js-variation-size .js-variation-dropdown'),
			klarnaWidget: $('klarna-placement'),
			classes: {
				active: 'active',
				hidden: 'h-hidden',
				minified: 'h-minified',
				productAddToCartAdded: 'b-product_add_to_cart-submit--added',
				thumbSelected: 'b-product_thumbnail-selected',
				thumbItem: 'js-primary_image',
				unselectable: 'js-unselectable',
				lowInStock: 'low-in-stock',
				pdpSlidesActive: 'b-product_slide-link--active',
				classOpen: 'open',
				carouselWrapper: 'js-owl_carousel'
			},
			selectors: {
				mainImageCarouselMarker: '.js-main_image_carousel',
				addToCart: '.js-add_to_cart',
				slideContent: '.js-product_slide-content',
				carouselWrapper: '.js-owl_carousel',
				carouselActiveItem: '.js-primary_image',
				recommendationsCarouselItem: '.js-product_tile_wrapper',
				thumbnailItem: '.js-thumbnail',
				mainImageCntr: '.js-container_main_image',
				thumbnailsSlider: '.js-thumbnails_slider',
				thumbItem: '.b-owl_carousel-item',
				thumbClonedItem: '.b-owl_carousel-item:not(".cloned")',
				soldoutMessageCont: '.js-soldout-message',
				swatchHover: '.b-swatches_size-item',
				lowInStockSelected: '.js-swatches_size-item-selected',
				lowInStockMsg: '.js_low-in-stock-msg',
				addToCartBtn: '.js-add-to-cart-plp-btn-container',
				pdpColorsCarousel: '.js-pdp-colors-carousel',
				horCarousel: '.jcarousel-clip-horizontal',
				lastVisitedCarousel: '.js-owl-last-visited',
				productTabs: '.js-product_tabs',
				productTabsContent: '.js-product_tile',
				fancyboxWrap: '.fancybox-wrap',
				toggleVariationSel: '.js-toggle-variation',
				variationDropdownSel: '.js-variation-dropdown',
				variationItem: '.js-variation-item',
				variationColors: '.js-swatches_color-link',
				target: '.target',
				thumbnailImg: '.js-img_product_thumbnail',
				klarnaWidget: 'klarna-placement'
			}
		};
		$cache.soldoutMsgText = $cache.productDetail.find($cache.selectors.soldoutMessageCont).html();
		$cache.primaryImagesArr = [];
	}

	function initializeDOM() {
		if (app.device.isMobileView()) {
			// colors carousel
			app.owlcarousel.initCarousel($($cache.selectors.pdpColorsCarousel));
			initPlpMobileActions();
		}
	}

	function initializeEvents() {
		$cache.pdpSlidesLink.on('click', function() {
			var $this = $(this);
			var activeLinks = $cache.pdpSlideMenu.find('.' + $cache.classes.pdpSlidesActive);
			if (!$this.hasClass($cache.classes.pdpSlidesActive)) {
				activeLinks.next().slideUp();
				activeLinks.removeClass($cache.classes.pdpSlidesActive);
			}
			$this.parent().find($cache.selectors.slideContent).slideToggle();
			$this.toggleClass($cache.classes.pdpSlidesActive);
		});

		$cache.thumbnails.on('click', function(e) {
			e.stopPropagation();
		});

		// fix for iPad owl carousel opacity problem
		$cache.container.find($cache.selectors.mainImageCarouselMarker).on('to.owl.carousel', function(evt, itemPosition) {
			if (itemPosition) {
				var carouselItem = $(this).find('.b-owl_carousel-item:not(.cloned) > .js-producttile_image').eq(itemPosition);

				if (carouselItem.length && app.device.currentDevice() === 'tablet' && app.device.isIOS()) {
					carouselItem.css({ opacity: 1 });
				}
			}
		});

		initOpenPopupLinkEvents();
		$cache.needHelpLink.on('click', function(e) {
			e.preventDefault();
			app.fancybox.open($cache.needHelpContainer.html());
		});
		soldoutMessage();

		// switches thumbnail when slide in product carousel is changed
		$cache.productImagesCntr.on('translated.owl.carousel', $cache.selectors.carouselWrapper, function() {
			var index = $(this).find($cache.selectors.carouselActiveItem).find($cache.carouselImageSel).data('imageindex');
			switchThumbnail(index);
		});

		initThumbnailsSlider($($cache.selectors.thumbnailsSlider));

		if ($cache.tabs.length) {
			// refresh carousels on tabs
			$cache.tabs.on('tabsactivate', function() {
				$(this).find('.owl-loaded').each(function() {
					var instance = app.owlcarousel.getInstance($(this));
					if (instance) {
						instance.onResize();
					}
				});
			});
		}

		// accordion
		$cache.tabsTitle.on('click', function() {
			$(this).toggleClass($cache.classes.active).next().slideToggle(300);
		});

		handleEmptyTabsCaller();

		$cache.container.on('click', $cache.selectors.variationColors, function() {
			var url = $(this).attr('href');
			window.history.replaceState({}, '', url);
		});

		$cache.backButton.on('click', function(e) {
			e.preventDefault();
			window.history.back();
		});

		adaptCarouselViewport();

		window.addEventListener('resize', function() {
			adaptCarouselViewport();
		});
	}

	function adaptCarouselViewport() {
		const carouselWidget = $($cache.selectors.mainImageCarouselMarker);
		carouselWidget.removeClass($cache.classes.minified);
		if (carouselWidget.hasClass($cache.classes.carouselWrapper)) {
			carouselWidget.css('max-height', '100%');
		}
	}

	function initializeDocumentEvents() {
		$cache.document.on('changeVariation.changed', function(e, data) {
			data.container.find($cache.selectors.carouselWrapper).each(function() {
				app.owlcarousel.initCarousel($(this));
			});
		});

		$cache.document.on('product.added', function(e, container) {
			var addToCart = $(container).find($cache.selectors.addToCart);

			addToCart.addClass($cache.classes.productAddToCartAdded);
			addToCart.html(app.resources.ADDED_TO_CART);
		});

		if (!app.device.isMobileView()) {
			$cache.document.on('product.invalid', function() {
				$cache.sizeWrapper.removeClass($cache.classes.hidden).parent().toggleClass($cache.classes.classOpen);
			});
		}

		// synchronize thumbnails carousel and main pdp carousel positions
		$cache.document.on('product.thumbnail.change', function(event, params) {
			if (params.isZoomed === 0) {
				var $mainImage = $($cache.selectors.mainImageCntr);

				$mainImage.find($cache.selectors.thumbItem).removeClass($cache.classes.thumbItem);
				$mainImage.find($cache.selectors.thumbClonedItem).find('[data-imageindex="' + params.currentIndex + '"]').parent($cache.selectors.thumbItem).addClass($cache.classes.thumbItem);
				$($cache.selectors.mainImageCarouselMarker).trigger('to.owl.carousel', params.currentIndex);
			}
		});

		// when on pdp more than one image
		$cache.document.on('product.zoom.aftershow', function(event, params) {
			switchThumbnail(params.currentIndex);
		});

		// try to reuse the already initialized Klarna widget;
		// if unsuccessful, initialize the new one
		$cache.document.on('product.variation.reloaded', function(e, data) {
			var $oldWidget = $cache.klarnaWidget;
			var $newWidget = data.target.find($cache.selectors.klarnaWidget);

			if ($newWidget.length) {
				if ($oldWidget.length && app.util.doElementsHaveSameAttributes($oldWidget.get(0), $newWidget.get(0))) {
					$newWidget.replaceWith($oldWidget);
				} else {
					$cache.klarnaWidget = $newWidget;
					app.components.global.klarnapayments.siteMessageUpdate();
				}
			}
		});

		// reinit сolors carousel / nocarousel after ajax
		$cache.document.on('product.variation.reloaded product.sizes.reloaded', function(event, params) {
			if (app.device.isMobileView()) {
				var container = $($cache.selectors.pdpColorsCarousel);

				container.data('settings', $.extend(app.configs.carouselSettings.owl.swatches, { startPosition: params.start || 0 }));
				app.owlcarousel.initCarousel(container);

				initPlpMobileActions();

				if (!params.sizeChanged) {
					app.mediaplayer.init();
				}

				initInstagramBanner();
			}
		});

		// init carousel with recommendations
		$cache.document.on('carousel.init', function(e, data) {
			if (data.container) {
				app.owlcarousel.initCarousel($(data.container).find($cache.selectors.horCarousel));
			}
		});

		// init last visited carousel
		$cache.document.on('last.visited.loaded', function() {
			if (!app.device.isMobileView()) {
				app.owlcarousel.initCarousel($($cache.selectors.lastVisitedCarousel));
			}
		});

		$cache.document.on('recommendations.loaded last.visited.loaded', handleEmptyTabsCaller);

		$cache.document.on('product.lowinstock.load', function() {
			var messageContainer = $cache.productDetail.find($cache.selectors.lowInStockSelected);
			var message = messageContainer.find($cache.selectors.lowInStockMsg).text();
			var soldoutMessageCont = $cache.productDetail.find($cache.selectors.soldoutMessageCont);

			if (message) {
				soldoutMessageCont.addClass($cache.classes.hidden);
				messageContainer.addClass($cache.classes.lowInStock);
			}
		});
	}

	function switchThumbnail(index) {
		var $thumbImg = $($cache.thumbnailsCntrSel).find('[data-thumbnailindex=' + index + ']');

		if ($thumbImg.length) {
			$($cache.thumbnailsCntrSel).find($cache.selectors.thumbnailItem).removeClass($cache.classes.thumbSelected);
			$thumbImg.parent($cache.selectors.thumbnailItem).addClass($cache.classes.thumbSelected);
		}
	}

	function modifyZoomCntr(container) {
		container.find($cache.carouselImageSel).not($cache.selectors.target).parent().remove();
		container.find($cache.selectors.mainImageCntr).html(container.find($cache.selectors.carouselActiveItem).attr('style', ''));

		return container;
	}

	function initPlpMobileActions() {
		$cache.share.on('click', function() {
			$(this).siblings('div').toggle();
		});
	}

	function initInstagramBanner() {
		var instafeedCreds = app.preferences.services.instagram.homepage;
		var options = {
			get: instafeedCreds.get,
			userId: Number(instafeedCreds.userId),
			accessToken: instafeedCreds.accessToken,
			clientId: instafeedCreds.clientId,
			limit: instafeedCreds.limit,
			resolution: instafeedCreds.resolution,
			template: configs.template
		};
		app.instafeed.init(options);
	}

	function soldoutMessage() {
		$cache.productDetail
			.on('mouseover', $cache.selectors.swatchHover, function() {
				var $this = $(this);
				var message = '';
				var soldoutMessageCont = $cache.productDetail.find($cache.selectors.soldoutMessageCont);

				if ($this.hasClass($cache.classes.unselectable)) {
					message = $cache.soldoutMsgText;
				} else {
					message = $this.find($cache.selectors.lowInStockMsg).text();

					if (message) {
						var soldoutMsg = soldoutMessageCont.html() || ' ';
						soldoutMessageCont.addClass($cache.classes.lowInStock).html(message).attr('data-soldout-msg', soldoutMsg);
					}
				}
			})
			.on('mouseout', $cache.selectors.swatchHover, function() {
				var soldoutMessageCont = $cache.productDetail.find($cache.selectors.soldoutMessageCont);
				var message = soldoutMessageCont.data('soldout-msg');

				if (message) {
					soldoutMessageCont.removeClass($cache.classes.lowInStock).html(message).removeAttr('data-soldout-msg');
				}
			});
	}

	// overridden private function from app.product.js (overlay, width, height changed for fancybox)
	function initOpenPopupLinkEvents() {
		// onclick event show popup 'care and details'
		$cache.openCarePopup.on('click', function() {
			var fancyParent;
			var activeOverlay = false;

			app.fancybox.open($cache.popupContent, {
				helpers: {
					overlay: true
				},
				autoSize: false,
				width: 410,
				autoHeight: true,
				afterShow: function() {
					fancyParent = $($cache.selectors.fancyboxWrap).parents(); // normally html and body
					fancyParent.on('click', function(e) {
						e.stopPropagation();

						if (activeOverlay) {
							app.fancybox.close();
						}

						activeOverlay = true;
					});
					$($cache.selectors.fancyboxWrap).on('click', function(event) {
						event.stopPropagation();
					});
				},
				afterClose: function() {
					fancyParent.off('click');
				}
			});
		});
	}

	function initThumbnailsSlider(element) {
		if (element.find($cache.selectors.thumbnailItem).length > 5) {
			element.thumbnailsSlider({
				itemCount: 5,
				arrowUpClass: 'b-product_thumbnails-arrow_up',
				arrowDownClass: 'b-product_thumbnails-arrow_down'
			});
		}
	}

	function handleEmptyTabsCaller() {
		app.product.handleEmptyTabs($cache.selectors.productTabs, $cache.selectors.productTabsContent);
	}

	function hoverVariant(e) {
		var $target = $(e.target);
		var $container = $target.data('container-element') ? $target.closest($target.data('container-element')) : $(e.delegateTarget);
		var $primaryImages = $container.find(app.product.getImgSelector($container));
		var thumbsClass = $(this).data('thumbs');
		var $thumbs = $container.find(thumbsClass);

		$primaryImages.hide();

		if (e.type === 'mouseenter') {
			$primaryImages.each(function(index) {
				var $item = $(this);
				var picturePar = $item.parent();
				var primaryImageObj = {
					src: $item.attr('src'),
					alt: $item.attr('alt'),
					title: $item.attr('title'),
					originalSrcset: picturePar ? picturePar.data('originalsrcset') : null
				};

				$cache.primaryImagesArr.push(primaryImageObj);

				var $thumb = $thumbs.find($cache.selectors.thumbnailImg + '[data-thumbnailindex=' + index + ']');

				var dataImageObj = {
					lgimg: $thumb.data('lgimg'),
					srcset: $thumb.data('srcset')
				};

				if (dataImageObj) {
					var sourceSib = $item.siblings('source');

					$item.attr({
						src: dataImageObj.lgimg.hires,
						alt: dataImageObj.lgimg.alt,
						title: dataImageObj.lgimg.title
					});

					if (dataImageObj.srcset && sourceSib.length) {
						sourceSib.attr('srcset', dataImageObj.srcset);
					}
				}
			});
		} else if (e.type === 'mouseleave') {
			$primaryImages.each(function(index) {
				var $item = $(this);
				var oldPrimaryImageObj = $cache.primaryImagesArr[index];
				var sourceSib = $item.siblings('source');

				if (oldPrimaryImageObj) {
					$item.attr({
						src: oldPrimaryImageObj.src,
						alt: oldPrimaryImageObj.alt,
						title: oldPrimaryImageObj.title
					});

					if (oldPrimaryImageObj.originalSrcset && sourceSib.length) {
						sourceSib.attr('srcset', oldPrimaryImageObj.originalSrcset);
					}
				}
			});
			$cache.primaryImagesArr = [];
		}

		$primaryImages.show();
	}

	/**
	 * @namespace app.product.custom public object
	 */
	app.components = app.components || {};
	app.components.product = app.components.product || {};
	app.components.product.custom = {
		init: function(params) {
			var container = 'container' in params && params.container instanceof $ ? params.container.find('.js-pdp_primary_content') : $('.js-pdp_primary_content');
			if (!container.length || container.hasClass('js-product-custom-initialized')) {
				return;
			}

			initializeCache(container);
			if ($($cache.selectors.mainImageCntr).find($cache.selectors.mainImageCarouselMarker)) {
				app.product.modifyZoomCntr = modifyZoomCntr;
			}
			initializeDOM();
			initializeEvents();
			container.addClass('js-product-custom-initialized');

			if (!documentEventsInitialized) {
				initializeDocumentEvents();
				documentEventsInitialized = true;
			}
		},
		handleEmptyTabsCaller: handleEmptyTabsCaller,
		hoverVariant: hoverVariant
	};
}(window.app = window.app || {}, jQuery));
