(function (app, $) {
	var $cache = {};
	
	function initializeCache() {
		$cache = {
			countiesList : $('.js-footer_min_country_selector'),
			languagesList : $('.js-footer_min_language_selector'),
			footerContainer : $('.js-footer_container'),
			selectorAboveClass : 'b-selectors_above'
		};

		//calculation based on childs as elements are minimized with 0 height
		$cache.higher = (($($cache.countiesList.children()[0]).outerHeight() > $($cache.languagesList.children()[0]).outerHeight()) ?
			$cache.countiesList :
			$cache.languagesList);
	}

	function initializeEvents() {
		$(document).on('toggler.toggled', function(event, object) {
			if ((object.$toggleElement.get(0) === $cache.countiesList.get(0) || object.$toggleElement.get(0) === $cache.languagesList.get(0)) &&
					!object.$toggleElement.hasClass(object.toggleToHeightClass)) {

				//reset to default behaviour in bellow mode
				$cache.higher.removeClass($cache.selectorAboveClass);
				object.$toggleElement.removeClass($cache.selectorAboveClass);

				if (!app.util.isVisibleFullHeight($cache.higher.children()[0])) {
					object.$toggleElement.addClass($cache.selectorAboveClass);
				}
			}
		});
	}

	/*************** app.components.footer.custom public object ***************/
	app.components = app.components || {};
	app.components.footer = app.components.footer || {};
	app.components.footer.custom= {
      init : function () {
        initializeCache();
        initializeEvents();
      }
  };
})(window.app = window.app || {}, jQuery);
