const CARDINAL_COMMERCE_URL = app.debugMode === app.constants.PRODUCTION_SYSTEM // production have separate url
	? 'https://centinelapi.cardinalcommerce.com'
	: 'https://centinelapistag.cardinalcommerce.com';

(function(app) {
	function WorldpayThreeDS() {
		app.popups.Popup.call(this, 'WorldpayThreeDS');
	}

	WorldpayThreeDS.prototype = Object.create(app.popups.Popup.prototype);
	WorldpayThreeDS.prototype.constructor = app.popups.Popup;

	WorldpayThreeDS.prototype.open = function(threeDSJWT) {
		this.markup = getMarkup();
		app.popups.Popup.prototype.open.call(this);

		const iframe = this.$markup.find('iframe')[0];

		iframe.contentWindow.document.body.innerHTML = getIframe3DSLayout(threeDSJWT);
		iframe.contentWindow.document.forms.challengeFrame.submit();
		this.$markup.find('.js-popup-close-button').remove();

		app.progress.hide();
	};

	function getMarkup() {
		return app.util.renderTemplate(document.getElementById('worldpayThreeDS').innerHTML);
	}

	function getIframe3DSLayout(threeDSJWT) {
		return `
			<form id="challengeFrame" name="challengeFrame" method="POST" action="${CARDINAL_COMMERCE_URL}/V2/Cruise/StepUp" onload="this.submit();">
				<input type="hidden" name="JWT" value="${threeDSJWT}" />
			</form>
		`;
	}

	app.popups.WorldpayThreeDS = new WorldpayThreeDS();
})((window.app = window.app || {}), jQuery);
