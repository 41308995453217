class Observable {
	constructor() {
		this.listeners = [];
	}

	subscribe(listenerToAdd) {
		if (!this.listeners.find((listener) => listener === listenerToAdd)) {
			if (typeof listenerToAdd.update === 'function') {
				this.listeners.push(listenerToAdd);
			}
		}
	}

	unsubscribe(listenerToRemove) {
		this.listeners = this.listeners.filter((listener) => listener !== listenerToRemove);
	}

	unsubscribeAll() {
		this.listeners = [];
	}

	notify(updateID, data) {
		this.listeners.forEach((listener) => listener.update(updateID, data));
	}
}

export default Observable;
