(function (app, $) {
	var $cache = {},
		configs = {},
		specificConfigs = {},
		preferences = app.preferences,
		urls = app.urls,
		resources = app.resources,
		msPerDay = 86400000,
		pageData = app.page.pageData,
		checkout = "CHECKOUT";

	function initializeConfigs(params) {
		configs = {
			popupHandler : popupHandler,
			wrapperClass : '' 
		};

		specificConfigs = app.componentsconfig.specific.global.components["global.newsletter"];

		if (params) {
			configs = $.extend(true, {}, configs, params);
		}
	}

	function initializeCache() {
		$cache = {
			body: $('body'),
			newsletterPopupContainer : $('#js-newsletter-popup-container'), //Newsletter popup slot has this html content
			simpleNewsletterPage : $(".js-simple_newsletter-page").length > 0,
			sectionSel: '.js-section',
			isCookiesBlockDisplay: app.device.isMobileView() ? 
					!(app.components.global.firstvisitbanner.getMode() === 'fancybox' && !$('.js-first-visit-banner').hasClass('h-hidden')) : true,
			document : $(document),
			window : $(window),
			subscribeToNewsletterPopup : $('.js-subscribe-to-newsletter-popup'),
			openReminderForm: '.js-remindcoupon_button',			
			sendRemindSel: '.js-send-remind',
			sendRemindSelForm : '.js-remind-coupon-form',
			promoCodeSel: '.js-promo-code',
			popupInitialized: false,
			categoryID: (pageData && typeof pageData.currentCategoryID !== 'object' && pageData.currentCategoryID) || ''
		};
	}

	function initializeEvents() {
		if ($cache.newsletterPopupContainer.length && preferences.enableNewsletterPopup && $cache.isCookiesBlockDisplay) {
			var minClicksToShowNewsletterPopup = (app.device.isMobileView() && preferences.minClicksToShowNewsletterPopupMobile > 0) ? preferences.minClicksToShowNewsletterPopupMobile : preferences.minClicksToShowNewsletterPopup;

			if (app.currentCustomer.getUserClicksNumber() >= minClicksToShowNewsletterPopup) {
				var nlPopupCount = getNewsletterCookie(), // values: null, 1 or 2
					nlPopupSession = getNewsletterSessionCookie();

				if (isLogInSubscribeTime() || ((nlPopupCount || 0) < 2 && !nlPopupSession)) {
					$cache.popupInitialized = true;
					configs.popupHandler({
						popupPromoCategory: !!$cache.categoryID
					});
				} else {
					$cache.document.trigger('promoBanner.show');
				}
			}
		}

		$cache.subscribeToNewsletterPopup.on('click', function() {
			configs.popupHandler({
				categoryID: $cache.categoryID
			});
			return false;
		});

		$cache.document.on('click', $cache.sendRemindSel, function (e){
			e.preventDefault();
			var form = $($cache.sendRemindSelForm);
			if (!form.valid()) {
				return false;
			}
			var url = app.util.appendParamsToUrl(form.attr('action'), {format: 'ajax'});
			var data = form.serialize() + '&' + 'promoCode='+ $($cache.promoCodeSel).text().trim();
			$.ajax({
				url: url,
				type: 'POST',
				data: data
			})
				.done(function(data) {
					app.fancybox.open({
						content: data,
						type: 'html'
					});
				})

				.fail(function() {
					location.href = location.href;
				});

			return false;
		});

		$cache.document.on('click', $cache.openReminderForm, function (){
			var options = {
				type: 'ajax',
				wrapCSS : 'fancybox-sendcoupon',
				afterShow: function() {
					app.validator.init();
					app.util.limitCharacters();
				}
			};
			app.fancybox.open(app.urls.sendCoupon, options);
		});		
	}

	function showNewsletterPopupOnCurrentPage () {
		return app.configs.pagesWhereShowNewsletterPopup.indexOf('*') !== -1 || app.configs.pagesWhereShowNewsletterPopup.indexOf(app.page.currentPage) !== -1;
	}

	function popupHandler(additionalParameters) {
		if ($cache.simpleNewsletterPage || !showNewsletterPopupOnCurrentPage()) {
			return;
		}

		var options = {
			type: 'ajax',
			afterShow: function() {
				$cache.document.trigger('newsletterpopup.opened');
			},
			onUpdate: function(){
				$cache.document.trigger('newsletterpopup.updated');
			},
			beforeClose: function() {
				$cache.document.trigger('newsletterpopup.beforeclosed');
			},
			afterClose: function() {
				$cache.document.trigger('newsletterpopup.closed');
			},
			tpl: { closeBtn: '<span class="fancybox-close js-close_newsletter" tabindex="0" aria-label="' + resources.NL_CLOSE_ARIALABEL + '" role="button"></span>' },
			wrapCSS : 'l-newsletter_popup_desktop js-newsletter_popup-wrapper' + configs.wrapperClass
		};

		var dataOptions = $cache.newsletterPopupContainer.data('fancyboxOptions');
		if (dataOptions) {
			options = $.extend(true, options, dataOptions);
		}

		if (specificConfigs) {
			options = $.extend(true, options, specificConfigs);
		}

		var url = urls.showNewsletterPopup;
		if (additionalParameters) {
			if (additionalParameters.categoryID) {
				url = app.util.appendParamToURL(url, 'categoryID', additionalParameters.categoryID);
			}
			if (additionalParameters.popupPromoCategory) {
				url = app.util.appendParamToURL(url, 'popupPromoCategory', additionalParameters.popupPromoCategory);
			}
		}
		if (preferences.isMobileNewsletterEnabled) {
			$cache.document.on('newsletterpopup.opened', function(){
				$('.fancybox-overlay.fancybox-overlay-fixed').off('click.overlay');
				$cache.window.on('orientationchange.fb', function(){
					$cache.window.scrollTop(0);
				});
			});
			options.wrapCSS = 'l-newsletter_popup js-newsletter_popup-wrapper';
			setTimeout(function () {
				app.fancybox.open(app.util.appendParamToURL(url, 'template', preferences.newsletterPopupTmpl), options);
			}, app.util.getConfig('ajaxNewsletter.newsletterPopupOpenTimeout'));
		} else {
			setTimeout(function () {app.fancybox.open(url, options)}, app.util.getConfig('ajaxNewsletter.newsletterPopupOpenTimeout'));
		}
	}

	function validateNewsletterSections(sectionWrapper){
		sectionWrapper = sectionWrapper + ' ' || '';
		var sections = $(sectionWrapper + $cache.sectionSel),
			valid = true,	
			errorWrapper;

		if(sections.length){
			valid = false;
			errorWrapper = $(sectionWrapper + '.js-formsections_error');

			errorWrapper.empty();

			for(var i = 0, length = sections.length; i<length; i++){
				var section = sections.eq(i);
				if(section.is(':checked')){
					valid = true;
					break;
				}
			}

			if(!valid){
				errorWrapper.text(app.resources.SECTION_MISSED_ERROR);
			}
		}

		return valid;
	}

	function getNewsletterCookie() {
		return $.cookie('nlPopupCount');
	}

	function getNewsletterClosedTimeCookie() {
		return $.cookie('nlPopupClosedTime');
	}

	function getNewsletterSessionCookie() {
		return $.cookie('nlPopupCountSession');
	}

	function diffDays(time, date2) {
		var timeDiff = Math.abs(date2.getTime() - time);
		return Math.ceil(timeDiff / msPerDay);
	}

	function isLogInSubscribeTime() {
		if (app.currentCustomer.isAuthenticated() && !app.currentCustomer.isSubscribed()) {
			var popupClosedTime = getNewsletterClosedTimeCookie() || new Date(),
				popupFrequency = Math.abs(preferences.ecirclePopupFrequency) || 0;
			if (popupFrequency > 0 && diffDays(popupClosedTime, new Date()) > popupFrequency) {
				return true;
			}
		}
		return false;
	}

	function getPopupInitStatus() {
		return $cache.popupInitialized;
	}

	/*************** app.components.global.newsletter public object ***************/
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.newsletter = {
	// initializations
		init : function (params) {
			initializeConfigs(params);
			initializeCache();
			initializeEvents();
		},
		popupHandler : popupHandler,
		validateNewsletterSections: validateNewsletterSections,
		getPopupInitStatus : getPopupInitStatus
	};
})(window.app = window.app || {}, jQuery);
