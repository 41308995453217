/**
 * The component shows correspond banner for each 2-nd level category.
 * If category does not have a banner then top category banner showed.
 *
 * Menu structure
 *
 * - TopCategory1       (banner1default)
 *   - Level2category11 (banner11)
 *   - Level2category12 (banner12)
 * - TopCategory2       (banner2default)
 *   - Level2category21 (banner21)
 *   - Level2category22 (banner22)
 *
 */
(function(app, $) {
	var $cache = {};

	function initializeCache() {
		$cache = {
			timerId: null,
			navigationWrap: $('.js-menu_subcategory_wrapper'),
			document: $(document),
			disableLinks: '.l-main_navigation.m-active .b-menu_category-link',
			mActiveLevel2Links: '.l-main_navigation.m-active .js-menu_category-level_2-link',
			withSubcategories: 'm-with_subcategories',
			hHidden: 'h-hidden',
			hMinimized: 'h-minimized',
			tabletHeaderMenuNav: 'tablet-header-menu-nav',
			jsCtgChosenSelector: '.js-ctg-chosen',
			jsCtgChosenClass: 'js-ctg-chosen b-ctg-chosen',
			jsMenuSubcatWrapper: '.js-menu_subcategory_wrapper',
			jsMenuLevel2ItemSel: '.js-menu_category-level_2-item',
			jsMenuLevel2Link: '.js-menu_category-level_2-link',
			mWithoutBannerClass: 'm-without_banner',
			jsMenuCtg2BannerDef: '.js-menu_category-level_2-banner-default',
			jsMenuCtg2Banner: '.js-menu_category-level_2-banner',
			mActiveClass: 'm-active',
			mainHeader: $('.js-header_main'),
			headerTransparentClass: 'b-transparent_header',
			jsCloseArrow: $('.js-close_arrow')
		};
	}

	// auxiliary function for displaying subcategories with delay
	function showItem() {
		var $thisLevel = $(document).find($cache.jsCtgChosenSelector);
		var $wrapper = $thisLevel.find($cache.jsMenuSubcatWrapper);

		if ($cache.timerId) {
			return false;
		}

		$wrapper.removeClass($cache.hMinimized);
	}

	function ipadTriggerMouseleave(hoveredItem) {
		var chosenItem = $($cache.jsCtgChosenSelector);

		if (navigator.userAgent.match(/iPad/i) && chosenItem.length > 0 && !hoveredItem.hasClass($cache.jsCtgChosenClass)) {
			chosenItem.trigger('mouseleave');
		}
	}

	function initializeEvents() {
		// prevents plp open on tablet | opens level3 category list, when click on level2 category
		$cache.document.on('click', $cache.mActiveLevel2Links, function(e) {
			var $this = $(this);
			var currentItem = $this.parents($cache.jsMenuLevel2ItemSel);
			var subMenu = currentItem.find($cache.jsMenuSubcatWrapper);
			var isActive = $this.data('active');
			var $menu = $('.js-menu_category-level_2-wrapper .js-menu_category-level_3');

			if (!isActive) {
				e.preventDefault();

				if (!subMenu.hasClass($cache.hHidden)) {
					subMenu.toggleClass($cache.hHidden);

					return;
				}

				$menu.addClass($cache.hHidden);
				subMenu.toggleClass($cache.hHidden);
			}
		});

		$cache.jsCloseArrow.on('mouseenter', function(e) {
			e.preventDefault();
			$(this).closest('li').find($cache.jsMenuSubcatWrapper).addClass($cache.hMinimized);
		});

		// top level on-mouseenter
		$('.js-menu_category-item').on('mouseenter', function(e) {
			e.preventDefault();
			var $thisLevel = $(this);
			var $defaultBanner = $thisLevel.find($cache.jsMenuCtg2BannerDef);
			var $allBanners = $thisLevel.find($cache.jsMenuCtg2Banner);
			var $wrapper = $thisLevel.find($cache.jsMenuSubcatWrapper);
			var $level2Link = $thisLevel.find($cache.jsMenuLevel2Link);

			ipadTriggerMouseleave($thisLevel);

			if (!$wrapper.length && !$level2Link.length && ['mobile', 'tablet'].includes(app.device.currentDevice())) {
				var link = $thisLevel.find('a').attr('href');

				window.location.href = link;
			}

			if ($cache.timerId) {
				$thisLevel.toggleClass($cache.jsCtgChosenClass);
			} else {
				$wrapper.removeClass($cache.hMinimized);
				$cache.mainHeader.removeClass($cache.headerTransparentClass);
				$thisLevel.toggleClass($cache.jsCtgChosenClass);
				$cache.navigationWrap.removeClass($cache.mWithoutBannerClass);
				$allBanners.hide();
				$defaultBanner.show();

				// if there is no default banner for category
				if (!$defaultBanner.html()) {
					$cache.navigationWrap.addClass($cache.mWithoutBannerClass);
				}

				// bind mouseenter event handler for 2nd level onetime only
				if (!$thisLevel.data('isInitedlevel2')) {
					$thisLevel.data('isInitedlevel2', true);

					$level2Link.on('mouseenter', function() {
						var $this = $(this);

						var $banner = $defaultBanner;
						var index = $this.attr('data-slot');

						if (index) {
							var $curBanner = $thisLevel.find('.js-menu_category-level_2-banner-' + index);

							if ($curBanner.html()) {
								$banner = $curBanner;
								$cache.navigationWrap.removeClass($cache.mWithoutBannerClass);
							} else {
								$cache.navigationWrap.addClass($cache.mWithoutBannerClass);
							}
						}

						$allBanners.hide();
						$banner.show().addClass($cache.mActiveClass).siblings('div').removeClass($cache.mActiveClass);
					});
				}
			}
		});

		// hide subcategories block with a short delay
		$('.js-menu_category-item').on('mouseleave', function() {
			var $thisLevel = $(this);

			if ($thisLevel.hasClass($cache.jsCtgChosenClass)) {
				var $wrapper = $thisLevel.find($cache.jsMenuSubcatWrapper);

				$thisLevel.toggleClass($cache.jsCtgChosenClass);
				$cache.timerId = setTimeout(function() {
					$wrapper.addClass($cache.hMinimized);
					$cache.timerId = null;
					showItem();
				}, 500);
			}
		});

		$cache.document.on('click', $cache.disableLinks, function(e) {
			if ($(this).parents('div.' + $cache.tabletHeaderMenuNav).length === 0) {
				e.preventDefault();
			}
		});
	}

	/**
	 * @namespace app.components.global.categoryflyout public object
	 * */

	app.components = app.components || {};
	app.components.categoryflyout = app.components.categoryflyout || {};
	app.components.categoryflyout.custom = {
		// initializations
		// eslint-disable-next-line no-unused-vars
		init: function(params) {
			initializeCache();
			initializeEvents();
		}
	};
})(window.app = window.app || {}, jQuery);
