import Mustache from 'mustache';

(function(app, $) {
	var $doc = $(document);
	var $html = $('html');

	function Popup(type) {
		this.type = type;
		this.isOpened = false;
		this.markup = '';
		this.$markup = $();
	}
	Popup.prototype.open = function() {
		this.markup = getMarkup.call(this);
		this.$markup = $(this.markup);
		this.$markup.appendTo($('body'));
		this.initMarkup();
		this.isOpened = true;
		$html.addClass('html_popup_opened');
	};
	Popup.prototype.close = function() {
		if (this.isOpened) {
			this.isOpened = false;
		}

		$doc.off('keydown.' + this.type);
		$doc.off('click.' + this.type);
		$html.removeClass('html_popup_opened');
		this.removeFromDOM();
		app.popupsMgr.popupIsClosed(this);
	};
	Popup.prototype.initMarkup = function() {
		this.$markup.find('.js-popup-close-button').on('click', this.close.bind(this));

		var $content = this.$markup.find('.js-popup-content');

		if ($content.data('closeOnEsc') === true) {
			$doc.on('keydown.' + this.type, onEscKeyDownHandler.bind(this));

			$doc.on('dialog.showed.' + this.type, function() {
				$doc.off('keydown.' + this.type);
			}.bind(this));

			$doc.on('fancybox.closed.' + this.type, function() {
				$doc.on('keydown.' + this.type, onEscKeyDownHandler.bind(this));
			}.bind(this));
		}

		if ($content.data('closeOnOutsideClick') === true) {
			$doc.on('click.' + this.type, onOutsideClickHandler.bind(this));
		}

		if ($content.data('lock-scroll')) {
			$html.addClass('lock-scroll');
		}
	};
	Popup.prototype.removeFromDOM = function() {
		if (this.$markup instanceof jQuery && this.$markup.length > 0) {
			this.$markup.remove();
		}
	};
	function getMarkup() {
		const template = document.getElementById('popupTemplate').innerHTML;

		return Mustache.render(template, {
			type: this.type,
			markup: this.markup
		});
	}
	function onEscKeyDownHandler(e) {
		var KEY_CODE_ESC = 27;

		if (e.keyCode === KEY_CODE_ESC) {
			this.close();
		}
	}
	function onOutsideClickHandler(e) {
		if ($(e.target).closest('.js-popup').length === 0) {
			this.close();
		}
	}
	app.popups = {};
	app.popups.Popup = Popup;
})((window.app = window.app || {}), jQuery);
