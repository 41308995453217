(function (app, $) {
	var $cache = {};

	function initializeCache() {
		$cache = {
			document: $(document),
			productShareTextSel: '.js-product-share-text',
			productShareSel : '.js-product-share',
			hoverClass: 'b-product_share-tablet'
		};
	}

	function initializeEvents() {
		initShare();

		$cache.document.on('quickview.opened shopthelook.opened', function() {
			initShare();
		});
	}

	function initShare() {
		var $productShare = $($cache.productShareSel);
		var $productShareText = $($cache.productShareTextSel);

		if (app.device.isTabletUserAgent()) {
			$productShareText.on('touchstart', function(e) {
				e.preventDefault();
				e.stopPropagation();
				$productShare.addClass($cache.hoverClass);
			});

			$cache.document.on('touchstart', function(e) {
				if (!$(e.target).closest($cache.productShareSel)[0]) {
					$productShare.removeClass($cache.hoverClass);
				}
			});

			$cache.document.on('social.clicked', function() {
				$productShare.removeClass($cache.hoverClass);
			});
		}
	}

	/*************** app.components.social.custom public object ***************/
	app.components = app.components || {};
	app.components.social = app.components.social || {};
	app.components.social.custom= {
      init : function () {
        initializeCache();
        initializeEvents();
      }
  };
})(window.app = window.app || {}, jQuery);
