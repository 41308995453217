(function(app, $) {
	var $cache = {};

	function initializeCache() {
		$cache = {
			$verifiedStoresContent: $('.js-storelocator-verified-stores-content'),
			verifiedStoresBtn: '.js-storelocator-verified-stores'
		};
	}

	function initVerifiedStoresPopUp() {
		if (!$cache.$verifiedStoresContent.length) {
			return;
		}

		$($cache.verifiedStoresBtn).on('click', function(e) {
			e.preventDefault();

			app.fancybox.open($cache.$verifiedStoresContent, {
				width: app.device.isMobileView() ? '100%' : '380px',
				wrapCSS: 'b-storelocator_verified-stores-popup',
				autoSize: false
			});
		});
	}

	function initializeEvents() {
		initVerifiedStoresPopUp();
	}

	app.components = app.components || {};
	app.components.storelocator = app.components.storelocator || {};
	app.components.storelocator.custom = {
		init: function() {
			initializeCache();
			initializeEvents();
		}
	};
})(window.app = window.app || {}, jQuery);
