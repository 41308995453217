/**
 * TBD: check if this class still need to be part of OneApp
@class app.storefront
*/
(function(app, $) {
	var $cache = {};
	function initializeCache(params) {
		$cache = {
			html: $('html'),
			body: $('body'),
			homecontent: $('.js-homepage-main'),
			sections: $('.js-section'),
			footer: 'footer',
			header: $('header'),
			skipEl: 'js-skip-slot',
			reInitWhileResizeWindow: false,
			fullPageScrollBar: true,
			fullPageEnabledClass: 'fp-enabled'
		};
		$cache.homecontent.append("<a href='javascript:void(0)' id='moveDown'>&nbsp;</a>");
		$cache.arrow = $('#moveDown');

		if (params) {
			$cache = $.extend(true, {}, $cache, params);
		}
	}
	function initPlugins(reinit) {
		var isHomePage = app.page && app.page.currentPage && (app.page.currentPage === 'homepage');
		if ((app.device.isMobileView() && app.util.getConfig('fullpageMobile.disable')) || !isHomePage) {
			return false;
		}
		if (!reinit) {
			replaceFooter();
		}

		if (!$cache.fullPageScrollBar && !app.device.isMobileView()) {
			$cache.fullPageScrollBar = true;
		}

		var anchors = checkSlots();
		if ($cache.html.hasClass($cache.fullPageEnabledClass)) {
			$.fn.fullpage.reBuild();
		} else {
			$cache.homecontent.fullpage({
				anchors: anchors,
				scrollBar: $cache.fullPageScrollBar,
				navigation: true,
				resize: true,
				verticalCentered: false,
				fixedElements: '#moveDown',
				navigationPosition: 'right',
				sectionSelector: '.js-section',
				onLeave: function(index, nextIndex, direction) {
					if ((nextIndex + 1) >= $('.js-section').length) {
						$cache.arrow.hide();
					} else {
						$cache.arrow.show();
					}
					$cache.homecontent.trigger('fullpage.onleave', [index, nextIndex, direction]);
				},
				afterLoad: function(index, nextIndex) {
					$cache.homecontent.trigger('fullpage.afterload', [index, nextIndex]);
				}
			});
		}
	}
	function checkSlots() {
		var arr = [];
		var count = 0;
		$cache.sections.each(function(index, element) {
			if ($(element).children().length) {
				arr.push('Section' + count);
				count++;
			} else {
				$(element).remove();
			}
		});
		return arr;
	}
	function replaceFooter() {
		if ($cache.homecontent.length) {
			$cache.homecontent.append($($cache.footer));

			if (!$('.' + $cache.skipEl).length) {
				if (app.device.currentDevice() === 'mobile') {
					$('<div class="' + $cache.skipEl + ' sticky-logo js-slot"></div>').prependTo($cache.homecontent);
				} else {
					$('<div class="' + $cache.skipEl + ' sticky-logo js-slot"></div>').insertBefore($cache.footer);
				}
			}
		}
	}
	function initializeEvents() {
		if ($cache.reInitWhileResizeWindow) {
			$(window).on('resize orientationchange', function() {
				initPlugins(true);
			});
		} else {
			// eslint-disable-next-line no-undef,no-new
			new ResizeSensor($cache.header.get(0), function() {
				$.fn.fullpage.destroy('all');
				initPlugins(true);
			});
		}

		$cache.arrow.on('click', function() {
			$.fn.fullpage.moveSectionDown();
		});
	}

	app.components = app.components || {};
	app.components.storefront = app.components.storefront || {};
	app.components.storefront.fullpage = {
		init: function(params) {
			initializeCache(params);
			initPlugins();
			initializeEvents();
		}
	};
}(window.app = window.app || {}, jQuery));
