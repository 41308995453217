(function(app, $) {
	var $cache = {};

	function initializeCache() {
		$cache = {
			timerId: null,
			window: $(window),
			document: $(document),
			header: 'header',
			mainHeader: $('.js-header_main'),
			fullscreenBanners: $('.js-is_header_transparent'),
			slotTheme: 'js-hp-slot_light',
			headerTheme: 'dark-theme',
			hpSlot: '.js-section',
			homecontent: $('.js-homepage-main'),
			headerChangeEvent: 'header.change',
			body: $('body'),
			lightSlotClass: 'hp-slot-light',
			footerClass: 'js-footer_container',
			headerHoveredClass: 'header-hovered',
			headerTransparentClass: 'b-transparent_header'
		};
	}

	function initializeDom() {
		app.components.global.header.adaptBodyPadding();
	}

	// Add delay on HP for mouseleave header panel
	function initDelayHPHeaderMenu() {
		$cache.mainHeader.on('mouseenter', function() {
			if ($cache.timerId) {
				clearTimeout($cache.timerId);
			}

			$(this).addClass($cache.headerHoveredClass);

			$(this).removeClass($cache.headerTransparentClass);
		}).on('mouseleave', function() {
			var $el = $(this);

			$cache.timerId = setTimeout(function() {
				$el.removeClass($cache.headerHoveredClass);
				$cache.timerId = null;
			}, 500);
			$el.addClass($cache.headerTransparentClass);
		});
	}

	function initializeEvents() {
		$cache.window.resize(function() {
			app.components.global.header.adaptBodyPadding();
		});

		$cache.homecontent.on('fullpage.onleave, fullpage.afterload', function(e, origin, destination) {
			var activeSection = 'Section' + (destination - 1);
			var $el = $($cache.hpSlot + '[data-anchor=' + activeSection + ']');
			var dataCss = $el.data('cssclass');

			$cache.body.removeClass($cache.lightSlotClass);
			if (dataCss && dataCss.length) {
				$cache.body.addClass(dataCss);
			}

			if ($el.hasClass($cache.footerClass) && !$cache.body.hasClass($cache.lightSlotClass)) {
				$cache.body.addClass($cache.lightSlotClass);
			}

			if ($el.hasClass($cache.slotTheme)) {
				$cache.mainHeader.addClass($cache.headerTheme);
			} else {
				$cache.mainHeader.removeClass($cache.headerTheme);
			}
		});

		initDelayHPHeaderMenu();
	}

	/**
	 * @namespace app.components.homepage.custom public object
	 * */
	app.components = app.components || {};
	app.components.homepage = app.components.homepage || {};
	app.components.homepage.custom = {
		init: function() {
			initializeCache();
			initializeDom();
			initializeEvents();
		}
	};
}(window.app = window.app || {}, jQuery));
