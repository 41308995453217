(function (app, $) {
	var $cache = {},
		nlPopupCount,
		CLICK = "click",
		nl_pop_up = "NL POP UP",
		specificConfigs = {},
		configs = {};

	function initializeConfigs(params) {
		configs = {
			successCallback : successCallback,
			closePopupButton : ".js-close_newsletter",
			closeTimeout: 0
		};

		specificConfigs = app.componentsconfig.specific.global.components['newsletter.handlepopup'];

		if (params) {
			configs = $.extend(true, {}, configs, params);
		}
	}

	function initializeCache() {
		$cache = {
			submitPopupSelector : ".js-popup_submit",
			formForSubmit : $(".js-popup_form_for_submit"),
			formForSubmitSelector : ".js-popup_form_for_submit",
			fancyboxInnerSelector: ".fancybox-inner",
			genderField: $(".js-popup_form_gender"),
			closePopup : $(configs.closePopupButton),
			document : $(document)
		};
	}

	function initializeEvents() {

		$cache.document.on('newsletterpopup.opened', function() {
			initializeCache();
			initPopupEventsHandler();
		});

		if (app.validator){
			app.validator.init();
		}
	}

	function initPopupEventsHandler() {
		$cache.document.on("submit", $cache.formForSubmitSelector, function(e){
			e.preventDefault();
			e.stopPropagation();
			if (app.preferences.subscribeByEnter) {
				submitNewsleterPopup(e);
			}
		});
		$cache.document.on(CLICK, $cache.submitPopupSelector, function(e){
			e.preventDefault();
			submitNewsleterPopup(e);
		});
		$cache.closePopup.on(CLICK, closePopup);
		$cache.closePopup.on('keypress', function(e) {
			if (e.keyCode === 13) {
				closePopup();
				return false;
			}
		});
		$cache.document.one('fancybox.closed', setCookieForClosed);

		$cache.document.on('newsletter.popup.close', function() {
			closePopup();
		});
	}

	function submitNewsleterPopup(e) {
		var globalNewsletter = app.components.global.newsletter,
			sectionsStatus = true;
		$cache.formForSubmit.validate();
		if(globalNewsletter && globalNewsletter.validateNewsletterSections){
			sectionsStatus = app.components.global.newsletter.validateNewsletterSections($cache.formForSubmitSelector);
		}
		if (!$cache.formForSubmit.valid() || !sectionsStatus) {
			$cache.document.trigger('newsletter.form.invalid');
			return false;
		}
		if ($cache.genderField.length) {
			$cache.genderField.val($(e.target).val());
		}

		var customEvent = $.Event('simple.subscribe');

		$(document).trigger(customEvent, $cache.formForSubmit);
		if(customEvent.isDefaultPrevented()) return false;
		var url = app.urls.submitNewsletterPopup,
			data = $cache.formForSubmit.serialize();

		app.ajax.load({
			url : url,
			type: 'POST',
			data: data,
			callback : function(data) {
				if (data && data.redirectURL) {
					app.page.redirect(data.redirectURL);
				} else {
					$(document).trigger('newsletter.subscribed', [app.util.form2Object($cache.formForSubmit).newsletter_popup_email, nl_pop_up]);
					setNewsletterCookie(2);

					configs.successCallback(data);
				}
			}
		});
	}

	function setNewsletterCookie(value) {
		var expiration = new Date(),
			year = 365 * 24 * 60;
		expiration.setTime(expiration.getTime() + (year * 60 * 1000));
		nlPopupCount = $.cookie("nlPopupCount", value, { expires: expiration, path: "/" });
		if (value === 1) {
			$.cookie("nlPopupCountSession", true, { path: '/' });
		}
		$.cookie("nlPopupClosedTime", (new Date()).getTime(), { expires: expiration, path: '/' });
	}

	function getNewsletterCookie() {
		return $.cookie("nlPopupCount") || 0;
	}

	function closePopup() {
		if (configs.closeTimeout > 0) {
			setTimeout(function() {
				app.fancybox.close();
			}, configs.closeTimeout);
		} else {
			app.fancybox.close();
		}

		setCookieForClosed();
		//Remove handler on click outside if was closed with close button
		$cache.document.off('fancybox.closed', setCookieForClosed);
	}

	function setCookieForClosed() {
		setNewsletterCookie(2);
	}

	function successCallback(data) {
		if (data) {
			var options = {
				content: data,
				type: 'html',
				wrapCSS: 'l-newsletter_popup_desktop',
				afterShow: function() {
					$cache.document.trigger('newsletterpopupconfirmation.opened');
				}
			};

			options = $.extend(true, options, specificConfigs);

			app.fancybox.open(options);

			if ((app.device.currentDevice() === 'desktop' && app.configs.hideNewsletterConfirmationPopup.desktop)
				|| (app.device.currentDevice() === 'tablet' && app.configs.hideNewsletterConfirmationPopup.tablet)
				|| (app.device.currentDevice() === 'mobile' && app.configs.hideNewsletterConfirmationPopup.mobile)) {
				setTimeout(function() {
					app.fancybox.close();
					$cache.document.trigger('newsletterpopupconfirmation.closed');
				}, app.util.getConfig('ajaxNewsletter.thankyouFancyboxCloseTimeout'));
			}
		}
	}

	/**
	 * @namespace app.components.newsletter.handlepopup public object
	 **/
	app.components = app.components || {};
	app.components.newsletter = app.components.newsletter || {};
	app.components.newsletter.handlepopup = {
		init : function (params) {
			initializeConfigs(params);
			initializeCache(params);
			initializeEvents(params);
		},
		successCallback : successCallback
	};
}(window.app = window.app || {}, jQuery));
