import Observable from './Observable';

class VideoAdapter extends Observable {
	constructor($el, callback, uuid) {
		super();

		this.player = null;
		this.uuid = uuid;
		this.playing = false;
		this.muted = false;
		this.volume = 0;
	}

	volumeUp() {}

	volumeDown() {}

	toggleMute() {}

	play(source = null) {}

	pause(source = null) {}

	togglePlayPause() {
		if (this.playing) {
			this.pause.call(this, 'playPause');
		} else {
			this.play.call(this, 'playPause');
		}
	}
}

export default VideoAdapter;
