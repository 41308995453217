(function (app, $) {

	var $cache = {},
		configs = {
			updateHeader : true,
			mainBlockMoveSpeed : 'fast',
			hideClass : 'h-hidden',				// h-minimized
			mode : 'simple',					// fancybox
			fancyboxParams : {}
		};

	function initializeConfig(params) {
		configs = $.extend({}, configs, params);
	}

	function initializeCache() {
		$cache = {
			document : $(document),
			header : $('header'),
			headerChangeEvent : 'header.change',
			newsletterShowEvent: 'newsletterpopup.show',
			beforeHeader: $('.js-before_header'),
			fitPaddingEvent : 'fit.main.block.padding',
			loadModalCountryLangSelector : $('.js-load_modal'),
			firstVisitBanner : $('.js-first-visit-banner'),
			firstVisitBannerEmptySelector : '.js-first-visit-banner > div:visible',
			firstVisitBannerHeight : 0,
			cookiesInformerClass : '.js-cookies_informer',
			cookiesInformer : $('.js-cookies_informer'),
			cookiesOverlay : $('.js-cookies_informer-overlay'),
			closeOrKeepButton : $('.js-cookies_informer-close_button, .js-language_informer-link-keep'),
			main : $('main:not(.js-homepage-main)'),
			cookiesInformerWrapper : 'b-cookies_informer-wrapper',
			modalCountryLangWrapper : 'b-modal_country_lang-wrapper',
			fancyboxClose : '.fancybox-wrap .js-cookies_informer-close_button',
			hidden : 'h-hidden',
			minimized : 'h-minimized',
			oneClosed : 'b_oneclosed',
			hideClass : configs.hideClass,
			cookieClosedEvent : 'cookie.closed'
		}
	}

	function initializeEvents() {
		if (app.configs.firstVisit.expiresOnExit) {
			if (!window.name) {
				window.name = 'Win' + (new Date()).getTime();
			}
			var cookie;
			try {
				cookie = $.cookie(app.configs.firstVisit.keyName) ? JSON.parse($.cookie(app.configs.firstVisit.keyName)) : {};
				if (typeof cookie !== "object") cookie = {};
			} catch (e) {
				cookie = {};
			}
			if (cookie[window.name] && cookie[window.name]['first_visit']) return;

			var obj = {};

			obj[window.name] = { first_visit: true };
			cookie = $.extend(cookie, obj);
			$.cookie(app.configs.firstVisit.keyName, JSON.stringify(cookie), {
				path: '/'
			});
		} else {
			var localeRedirectPopupCookie = $.cookie('localeRedirectPopup');
			var showLocaleRedirectPopup = !!localeRedirectPopupCookie && localeRedirectPopupCookie !== '0';
			if ($.cookie(app.configs.firstVisit.keyName) || showLocaleRedirectPopup) return;

			$.cookie(app.configs.firstVisit.keyName, true, {
				expires: 3650,
				path: '/'
			});
		}

		$cache.firstVisitBanner.removeClass($cache.hidden);

		if(configs.mode === "fancybox" && $cache.firstVisitBanner.length) {
			var fancyParams = $.extend({}, {
				content: $cache.firstVisitBanner,
				wrapCSS: $cache.cookiesInformerWrapper,
				leftRatio: 0,
				topRatio: 0,
				afterShow: function() {
					$($cache.fancyboxClose).on('click', function(){
						var $informerItem = $(this).parents($cache.cookiesInformerClass);
						$informerItem.addClass($cache.hideClass);
						if ($($cache.firstVisitBannerEmptySelector).length == 0) {
							app.fancybox.close();
						}
					});
				}
			}, configs.fancyboxParams);

			app.fancybox.open($cache.firstVisitBanner, fancyParams);

		} else {
			if (configs.hideClass == $cache.minimized) {
				$cache.main.css({"padding-top" : parseInt($cache.main.css("padding-top")) + parseInt($cache.firstVisitBanner.outerHeight())});
			}
			$cache.firstVisitBannerHeight = parseInt($cache.firstVisitBanner.outerHeight());
		}

		$cache.closeOrKeepButton.on('click', closeBanner);

		$cache.cookiesOverlay.on('click', closeBanner);

		// load modal dialog when user click change
		$cache.loadModalCountryLangSelector.on('click', function() {
			// get content for modal dialog and open it
			app.fancybox.open(
				app.urls.loadModalCountryLangSelector, {
				type: 'ajax',
				wrapCSS: $cache.modalCountryLangWrapper,
				afterShow: function() {
					if (app.components && app.components.global && app.components.global.fbcountrylangselector) {
						app.components.global.fbcountrylangselector.init();
					}
				}}
			);
		});
	}

	function closeBanner(){
		if ($cache.cookiesInformer.length) {
			var headerHeightBeforeClose = $cache.header.height() + ( $cache.beforeHeader.height() || 0 ),
				$informerItem = $(this).parents($cache.cookiesInformerClass);
			if (configs.hideClass == $cache.minimized) {
				$cache.main.animate({"paddingTop" : parseInt($cache.main.css("padding-top")) - parseInt($informerItem.outerHeight())}, configs.mainBlockMoveSpeed);
			}
			$informerItem.addClass($cache.hideClass);
			$cache.cookiesInformer.addClass($cache.oneClosed);
			if ($($cache.firstVisitBannerEmptySelector).length == 0) {
				$cache.firstVisitBanner.addClass($cache.hideClass);
				if(configs.mode === "fancybox") {
					app.fancybox.close();
				}
			}
			var headerHeightAfterClose = $cache.header.height() + ( $cache.beforeHeader.height() || 0 );
			$cache.firstVisitBannerHeight = headerHeightBeforeClose - headerHeightAfterClose;
			$(document).trigger($cache.fitPaddingEvent);
		} else {
			$cache.firstVisitBanner.addClass($cache.hideClass);
		}

		$cache.document.trigger($cache.cookieClosedEvent);

		if (configs.updateHeader) {
			$cache.header.trigger($cache.headerChangeEvent, {height: $cache.firstVisitBannerHeight});
			$cache.document.trigger($cache.newsletterShowEvent);
		}
	}

  /*************** app.components.global.firstvisitbanner public object ***************/
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.firstvisitbanner = {
		// initializations
		init: function(params) {
			if (app.configs.cookiePolicyBanner) {
				initializeConfig(params);
				initializeCache();
				initializeEvents();
			}
		},
		getMode: function() {
			return configs.mode;
		}
	};
})(window.app = window.app || {}, jQuery);
