(function(app, $) {
	function ConfirmActionPopup() {
		app.popups.Popup.call(this, 'confirmActionPopup');
	}

	ConfirmActionPopup.prototype = Object.create(app.popups.Popup.prototype);
	ConfirmActionPopup.prototype.constructor = app.popups.Popup;

	ConfirmActionPopup.prototype.open = function(params) {
		this.onConfirm = params.onConfirm;
		this.onCancel = params.onCancel;
		this.markup = getMarkup(params.templateId);

		app.popups.Popup.prototype.open.call(this);
	};

	ConfirmActionPopup.prototype.initMarkup = function() {
		this.$markup.find('.js-cancel').on('click', (e) => this.close(e, this.onCancel));
		this.$markup.find('.js-confirm').on('click', (e) => this.close(e, this.onConfirm));

		app.popups.Popup.prototype.initMarkup.call(this);
	};

	ConfirmActionPopup.prototype.close = function(e, callback) {
		app.popups.Popup.prototype.close.call(this);

		if (typeof callback === 'function') {
			callback();
		} else {
			this.onCancel();
		}
	};

	function getMarkup(templateId) {
		return app.util.renderTemplate($(`#${templateId}`).html());
	}

	app.popups.ConfirmActionPopup = new ConfirmActionPopup();
})((window.app = window.app || {}), jQuery);
