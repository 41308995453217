//customerservice.custom
( function(app, $) {
	var $cache = {},
		animationSpeed = 500;

	function initializeCache() {
		$cache = {
			document: $(document),
			bodyElements: $('html, body'),
			attachmentInputSel: '.js-attachment',
			fileattachmentSel: '.js-fileattachment',
			countryInStoreSelect: '.js-countryinstore_select select',
			cityInStoreSelect: '.js-cityinstore_select',
			storeInStoreSelect: '.js-storeinstore_select',
			contactusGoToButton: '.js-customerservice-goto-form',
			contactUsComplexFormsSel: '.js-customer_service_forms-wrapper > div',
			hHiddenClass: 'h-hidden_minor',
			header: 'header',
			warningTextSel: '.f-warning',
			fileNameAttachmentHiddenSel: '.js-filename-attachment-',
			binaryAttachmentHiddenSel: '.js-binary-attachment-'
		};
	}
	
	function initializeEvents(){
		contactUsStorelocatorEvents();
		contactUsGoToFormEvents();
		attachmentFormEvents();
	}
	
	function contactUsStorelocatorEvents(){
		$($cache.countryInStoreSelect).on('change', function(){
			var params = {
				propName: 'countryCode',
				propGet: 'city'
			};
			app.components.global.storelocator.fetchStoreDependencies($($cache.countryInStoreSelect), $($cache.cityInStoreSelect), params, null, [$($cache.storeInStoreSelect)]);
		});
		
		$($cache.cityInStoreSelect).on('change', function(){
			var params = {
				propName: 'city',
				propGet: 'address1'
			};
			app.components.global.storelocator.fetchStoreDependencies($($cache.cityInStoreSelect), $($cache.storeInStoreSelect), params);
		});
	}
	
	function contactUsGoToFormEvents(){
		$($cache.contactusGoToButton).on('click', function(){
			var targetFormClass = $(this).data('form'),
				contactUsComplexForms = $($cache.contactUsComplexFormsSel),
				headerElement = $($cache.header),
				headerHeight = headerElement.css('position')  === 'fixed' ? headerElement.height() : 0,
				targetForm,
				scrollPos;
			
			if(targetFormClass && contactUsComplexForms.length){
				targetForm = $('.' + targetFormClass);
				contactUsComplexForms.addClass($cache.hHiddenClass);
				targetForm.removeClass($cache.hHiddenClass);
				$($cache.fileattachmentSel).removeClass('h-hidden');
				scrollPos = targetForm.offset().top - headerHeight;
				$cache.bodyElements.stop().animate({
					scrollTop: scrollPos
				}, animationSpeed);
			}
		});
	}
	
	function attachmentFormEvents(){
		$($cache.attachmentInputSel).on('click', function(){
			$(this).val('');
		})
		.on('change', function(){
			var	$this = $(this),
				inputAttachmentTextTargetName = $this.data('target') ? $this.data('target') : '',
				fileRestriction = $this.data('restrictions') ? new RegExp('\.('+$this.data('restrictions')+')$') : '',
				fileMaxSize = $this.data('maxsize') ? parseInt($this.data('maxsize')): 0,
				fileSize = this.files[0].size/Math.pow(10, 6),
				inputAttachmentTextWrapper,
				inputAttachmentTextTarget,
				inputAttachmentTextTargetWarningField;

			inputAttachmentTextWrapper = $this.closest(".field");
			inputAttachmentTextTarget = inputAttachmentTextWrapper.find('input[type="text"]');
			inputAttachmentTextTargetWarningFieldMessage = inputAttachmentTextWrapper.find($cache.warningTextSel + '_message');
			inputAttachmentTextTargetWarningField = inputAttachmentTextWrapper.find($cache.warningTextSel + '_text');
			inputAttachmentTextTargetWarningFieldMessage.hide();
			inputAttachmentTextTargetWarningField.text('');
			
			if (fileMaxSize && fileSize > fileMaxSize || fileRestriction && !fileRestriction.test(this.files[0].name)){
				inputAttachmentTextTargetWarningFieldMessage.show();
				inputAttachmentTextTargetWarningField.text(fileMaxSize && fileSize > fileMaxSize ? app.resources.ATTACHMENT_SIZE_WARNING : app.resources.ATTACHMENT_WARNING);
				$this.val('');
				inputAttachmentTextTarget.val('');
			} else {
				inputAttachmentTextTarget.val(this.files[0].name);
			}
		});
	}
	
	
	/**
	 * @namespace app.components.customerservice.custom public object
	 **/
	app.components = app.components || {};
	app.components.customerservice = app.components.customerservice || {};
	app.components.customerservice.custom = {
		init : function () {
			initializeCache();
			initializeEvents();
		}
	};

}(window.app = window.app || {}, jQuery));
