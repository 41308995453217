(function(app, $) {
	var $cache = {};

	function initializeCache() {
		$cache = {
			wishlistElement: {},
			header: $('header'),
			document: $(document),
			loginToggler: $('.js-login_dropdown-title.js-toggler'),
			wishlistToggler: $('.js-header_wishlist_icon.js-toggler'),
			wishlistFlyout: $('.js-wishlist_dropdown-flyout'),
			wishlistQtyVal: $('.js-wishlist-quantity_value'),
			popupWrapper: 'fancybox-login-wishlist',
			loginTogglerContent: $('.js-login_dropdown'),
			wishlistFlyoutMouseover: $('.js-wishlist-mouseover'),
			multiTemplate: $('#multiwishlist').html(),
			wishlistQtySel: '.js-wishlist_qty',
			wishlistFlyoutSel: '.js-wishlist_dropdown-flyout',
			wishlistRemoveSel: '.js-wishlist_flyout-remove',
			wishlistTitleLink: '.b-wishlist_flyout-title_link',
			wishlistHiddenClass: 'h-hidden',
			carouselOwlSel: '.js-wishlist_dropdown-flyout .js-owl_carousel',
			wishlistEmptyClass: 'b-wishlist_empty',
			wishlistAddedClass: 'b-add_to_wishlist--added',
			wishlistTooltip: $('.js-header-tooltip'),
			transitionEvent: app.util.getTransEndEvent()
		};
	}
	function initializeHelpers() {
		app.components.account.fakelogin.show = function() {
			$cache.document.trigger('wishlist.notauthenticated');
		};
	}
	function initializeEvents() {
		$cache.document.on('wishlist.uploaded', function() {
			var wishlistCount = $($cache.wishlistQtySel).eq(0).text() || '0';

			$cache.wishlistQtyVal.text(wishlistCount);
			var carousel = $($cache.carouselOwlSel);

			app.owlcarousel.initCarousel(carousel);
		});

		$cache.document.on('wishlist.added wishlistblock.removed', function(e, wishlistQuantity, doNotToggleBlock) {
			if (typeof doNotToggleBlock === 'undefined') {
				doNotToggleBlock = wishlistQuantity;
			}
			if (app.page.ns !== 'checkout') {
				var wishlistCount = $($cache.wishlistQtySel).eq(0).text() || '0';

				$cache.wishlistQtyVal.parent().toggleClass('h-hidden', !parseInt(wishlistCount));
				$cache.wishlistQtyVal.text(wishlistCount ? '(' + wishlistCount + ')' : '');
				app.wishlist.updateTooltipVal(wishlistCount);
				var carousel = $($cache.carouselOwlSel);

				carousel.one('owl.afterUpdate', function() {
					if (doNotToggleBlock !== true) {
						$cache.wishlistToggler.trigger('click', 'wishlist');
					}
					if (!parseInt(wishlistCount)) {
						$cache.wishlistToggler.addClass($cache.wishlistEmptyClass);
					}

					$cache.wishlistToggler.removeClass($cache.wishlistEmptyClass);
				});
				app.owlcarousel.initCarousel(carousel);
			}
		});

		$cache.document.on('wishlist.beforeadded', function(event, element) {
			$cache.wishlistElement = element;
		});

		$cache.document.on('wishlist.notauthenticated', function() {
			if (app.device.isMobileView()) {
				app.fancybox.open($cache.loginTogglerContent, {
					wrapCSS: $cache.popupWrapper,
					minHeight: 'auto',
					afterShow: function() {
						$cache.loginTogglerContent.removeClass('h-minimized');
					}
				});
			} else {
				$cache.loginToggler.trigger('click', 'wishlist');
			}
		});

		if (app.device.currentDevice() === 'desktop') {
			$cache.wishlistFlyoutMouseover.on('mouseenter', function() {
				$cache.wishlistTooltip.removeClass('h-hidden');
			})
				.on('mouseleave', function() {
					$cache.wishlistTooltip.addClass('h-hidden');
				});
		}

		$cache.header.on('click', '.js-wishlist_dropdown-flyout .js-add_to_cart_button', function() {
			var postdata = { Quantity: '1', cartAction: 'add' };
			var $this = $(this);
			var pliAttrs = $this.data('custom-attrs');

			if (pliAttrs) {
				postdata.product_data = pliAttrs;
			}

			postdata.pid = $this.data('pid');
			app.cart.update(postdata, function(response) {
				$cache.document.trigger('minicart.show', { html: response });
				$cache.document.trigger('minicart.afterload');
			});
		});

		$cache.header.on('click', $cache.wishlistRemoveSel, function() {
			var $this = $(this);
			var url = app.urls.removeProdyctFromWishlist;
			var data = {
				pliid: $this.data('item'),
				format: 'ajax'
			};

			if (app.preferences.emarsysWishlistReminderEnabled) {
				url = app.util.appendParamToURL(url, 'triggerWishlistReminder', true);
			}

			$.ajax({
				url: url,
				type: 'GET',
				data: data
			}).done(function(resData) {
				var $wishlistContainer = $($cache.wishlistFlyoutSel);

				$wishlistContainer.html($(resData).find($cache.wishlistFlyoutSel).html());

				var wishlistCount = $($cache.wishlistQtySel).eq(0).text() || '0';

				$cache.wishlistQtyVal.closest($cache.wishlistTitleLink).toggleClass($cache.wishlistHiddenClass, !parseInt(wishlistCount));
				$cache.wishlistQtyVal.text(wishlistCount ? '(' + wishlistCount + ')' : '');
				app.wishlist.updateTooltipVal(wishlistCount);

				if ($($cache.wishlistRemoveSel).length > 0) {
					var carousel = $($cache.carouselOwlSel);

					carousel.one('owl.afterUpdate', function() {
						$cache.wishlistToggler.trigger('update.header');
					});
					app.owlcarousel.initCarousel(carousel);
					$(document).trigger('wishlist.removed', [$wishlistContainer]);
				} else {
					$cache.wishlistToggler.addClass($cache.wishlistEmptyClass);
					$($cache.wishlistElement).removeClass($cache.wishlistAddedClass);
					$cache.document.trigger('toggle.hideall');

					var $slide = $($cache.wishlistToggler.data('slide'));

					$slide.one($cache.transitionEvent, function() {
						$cache.wishlistToggler.trigger('update.header');
					});
				}
			}).fail(function() {
				// eslint-disable-next-line no-self-assign
				location.href = location.href;
			});

			return false;
		});
	}

	app.components = app.components || {};
	app.components.wishlist = app.components.wishlist || {};
	app.components.wishlist.custom = {
		// initializations
		init: function() {
			initializeHelpers();
			initializeCache();
			initializeEvents();
		}
	};
})(window.app = window.app || {}, jQuery);
