(function(app, $) {
	var $cache = {};

	function initializeCache() {
		$cache = {
			document: $(document),
			custTogglerSel: '.js-custom-toggler',
			editorialContentSel: '.js-category_editorial--tile',
			clearFiltersSel: '.js-clear_search_filters',
			firstPageSeparatorSel: '.js-list_item_page[data-page="1.0"]'
		};
	}

	function showEditorialContent() {
		if ($($cache.firstPageSeparatorSel).length && !$($cache.clearFiltersSel).length) {
			$($cache.editorialContentSel).show();
		}
	}

	function initializeEvents() {
		$cache.document.on('refinements-update', function(e, extParams) {
			app.components.search.filters.init();

			if (extParams && extParams.tabClass) {
				$('.' + extParams.tabClass).siblings($cache.custTogglerSel).trigger('toggle');
			}
		});
		showEditorialContent();
		$cache.document.on('grid-update', function() {
			showEditorialContent();
		});
	}
	/**
	 * @namespace app.components.search.custom public object
	 * */
	app.components = app.components || {};
	app.components.search = app.components.search || {};
	app.components.search.custom = {
		init: function() {
			initializeCache();
			initializeEvents();
		}
	};
}(window.app = window.app || {}, jQuery));
