(function(app, $) {
	var $cache = {};
	var configs = {
		newsletterFormSelector: '#newsletter-simple-subscription',
		formFieldSelector: '.f-field',
		formFieldValidClass: 'f-state-valid',
		currentDevice: app.device.currentDevice(),
		template: "<a href='{{link}}' target='_blank'><img src='{{image}}' /></a>",
		defaultParallaxSpeed: -0.63,
		scrollDelta: 1.2,
		signGradientMap: {
			top: {
				max: 50,
				min: 65
			},
			bottom: {
				max: 8,
				min: 0
			}
		}
	};
	var changedElement = null;

	function initializeCache() {
		$cache = {
			html: $('html'),
			document: $(document),
			window: $(window),
			footer: $('footer'),
			header: $('header'),
			fancyboxOpenedSelClass: 'html_fancybox_opened',
			pageWrapperBlockSel: '.js-homepage-main, .js-pdp_main',
			assetWrapper: $('.js-asset_body-wrap'),
			assetImagesSel: '.js-asset_body-section_imgs',
			assetImgSel: '.js-asset_body-section_img',
			assetImgLinkSel: '.js-asset_body-section_link',
			assetImgLinkSelClass: 'js-asset_body-section_img',
			hpQuickViewLinkSel: '.js-hp_quickview',
			hpWishlistLinkSel: '.js-hp_wishlist-add',
			productCarousel: $('.js-owl_carousel-products'),
			sliderSection: $('section.js-scroll_to_me'),
			parallaxTypeElement: '*[data-parallax="yes"]',
			carouselWithThumbs: $('.js-owl_carousel-with-thumbs'),
			carouselItem: ':not(.cloned) > .js-b-assets_slider_with_thumbs-slides_item',
			carouselActiveItem: '.b-owl_carousel-item_active > .js-b-assets_slider_with_thumbs-slides_item',
			clonedItem: 'cloned',
			tabletHomepageWrapper: 'js-home-page-temporary_wrapper',
			homepageSlots: $("div[class^='l-hp-slot']"),
			carouselSignatures: '.js-fw-slider-text',
			hHidden: 'h-hidden',
			hShowFlex: 'h-show_flex',
			hShowInline: 'h-show_inline',
			signCenterBottomSel: 'varying-block--center_bottom',
			signRightSel: 'varying-block--right',
			signCenterSel: 'varying-block--center',
			signRightBottomSel: 'varying-block--right_bottom',
			stickyLogoSel: '.sticky-logo',
			videoTagSel: '.js-slot-video',
			videoPopup: '.js-slot-popup-video',
			wrapperMobile: '.js-site_wrapper',
			fullPageEnabledClass: 'fp-enabled',
			owlClass: 'js-owl_carousel',
			$owlCarousels: $('.js-owl_carousel'),
			owlDotSel: '.b-owl_carousel-nav_dot',
			owlLoadedClass: 'owl-loaded',
			owlItemActiveClass: 'js-primary_image',
			owlItemDefaultClass: '.b-owl_carousel-item',
			owlCarouselMainPDP: '.js-container_main_image',
			owlDotsSel: '.b-owl_carousel-nav_dots',
			owlSlideItemSel: '.b-owl_carousel-item:not(.cloned)',
			fullPageDotsSel: '#fp-nav ul',
			fullPageSectionSel: '.js-section',
			showroom: {
				countriesListSel: '.js-contacts_showroom_countries',
				countryBtnEl: $('.js-showroom_country_btn'),
				countryPopupSel: '.js-showroom_country_popup'
			},
			stopFake: {
				validatorFormEl: $('.js-stop-fake-validator'),
				successfulValidationEl: $('.js-stop-fake-success'),
				failedValidationEl: $('.js-stop-fake-failed')
			}
		};

		$cache.owlSel = '.' + $cache.owlClass;
	}

	function initializeDOM() {
		initHomepageInstagramBanner();
		reinitTabletInstagramStaticBanner();
	}

	function getTransforms(translate3d) {
		return {
			'-webkit-transform': translate3d,
			'-moz-transform': translate3d,
			'-ms-transform': translate3d,
			transform: translate3d
		};
	}

	function initializeEvents() {
		if (configs.currentDevice === 'desktop') {
			attachParallaxEffect();
		}

		$cache.document.on('suggestions.updated', function() {
			app.wishlist.init();
			initProductEvents();
		});

		if (!app.device.isMobileView()) {
			$cache.window.on('fullpage.afterload', function(e, origin, destination) {
				var totalSlide = $($cache.fullPageSectionSel).length;
				setDataAttributeNumberSlide($cache.fullPageDotsSel, 'data-current-slide', destination);
				setDataAttributeNumberSlide($cache.fullPageDotsSel, 'data-total-slide', totalSlide);
			});

			$cache.window.on('fullpage.scroll.scrolling', function(e, currentSlide) {
				setDataAttributeNumberSlide($cache.fullPageDotsSel, 'data-current-slide', currentSlide.sectionIndex + 1);
				hideNavigationOnLastSlide(currentSlide);
			});
		}

		if (app.device.currentDevice() === 'desktop') {
			$cache.assetWrapper.on('mouseenter', $cache.assetImagesSel, function() {
				staticProductShow.call(this);
			})
				.on('mouseleave', $cache.assetImagesSel, function() {
					staticProductHide.call(this);
				});

			$cache.window.on('scrolled.totop', function() {
				$cache.header.removeClass($cache.forcedBackgroundClass);
			});
		} else {
			$cache.window.on('fullpage.scroll.transform', function(e, currentSlide, opts) {
				var transition = 'all ' + opts.scrollingSpeed + 'ms ' + opts.easingcss3;

				$($cache.stickyLogoSel).css({
					'-webkit-transition': transition,
					transition: transition
				});

				hideNavigationOnLastSlide(currentSlide);

				$cache.window.trigger('fullpage.logo.move');
			});

			$cache.assetWrapper.on('click', $cache.assetImagesSel, function() {
				staticProductHideExceptTarget.call(this);
				staticProductShow.call(this);
			});

			// hide all static product on outside click
			$cache.document.on('click', function(e) {
				var target = $(e.target);
				if (!target.parent($cache.assetImgLinkSel).length && !target.hasClass($cache.assetImgLinkSelClass)
					&& target.parents($cache.pageWrapperBlockSel).length) {
					staticProductHideAll();
				}
			});

			$cache.window.on('fullpage.logo.move', function() {
				if ($cache.html.hasClass($cache.fullPageEnabledClass)) {
					var sectionIndex = $('.fp-section.active').index('.fp-section');
					var correction = -$cache.header.height();
					if (app.device.isIOS() && app.device.isPortraitOrientation()) {
						correction -= 60;
					}
					if (app.device.isAndroidOS()) {
						correction -= 30;
					}

					var translate;
					if (sectionIndex * $cache.window.innerHeight() < $cache.footer.position().top) {
						translate = sectionIndex * $cache.window.innerHeight();
					} else {
						translate = $cache.footer.position().top - $cache.window.innerHeight();
					}
					$($cache.stickyLogoSel).css(getTransforms('translate3d(0px, ' + (translate + correction) + 'px, 0px)'));
				}
			});
		}

		initProductEvents();

		initCarouselEvents();

		$cache.document.on('newsletter.subscribed', function() {
			$cache.document.on('fancybox.closed', function() {
				$(configs.newsletterFormSelector).find(configs.formFieldSelector).removeClass(configs.formFieldValidClass);
			});
		});

		$cache.document.on('newsletterpopup.opened simplenewsletterpopupconfirmation.opened newsletterpopupconfirmation.opened', function() {
			$cache.html.removeClass($cache.fancyboxOpenedSelClass);
		});

		$cache.stopFake.validatorFormEl.on('submit', function(e) {
			e.preventDefault();
			var $form = $(this);
			var url = $form.attr('action');
			var formData = $form.serializeArray();

			$.ajax({
				url: url,
				type: 'POST',
				data: formData
			}).done(function(responce) {
				if (responce.result) {
					$cache.stopFake.failedValidationEl.removeClass($cache.hShowFlex);
					$cache.stopFake.successfulValidationEl.addClass($cache.hShowFlex);
				} else {
					$cache.stopFake.successfulValidationEl.removeClass($cache.hShowFlex);
					$cache.stopFake.failedValidationEl.addClass($cache.hShowFlex);
				}
			});
		});

		if ($($cache.showroom.countriesListSel).length) {
			initShowRoomPopupEvents();
		}
	}

	function setDataAttributeNumberSlide(element, dataAttributeName, numberSlide) {
		$(element).attr(dataAttributeName, numberSlide.toString().padStart(2, 0));
	}

	function hideNavigationOnLastSlide(currentSlide) {
		var destination = currentSlide.sectionIndex + 1;
		var totalSlide = $($cache.fullPageSectionSel).length;

		if (destination >= totalSlide) {
			$($cache.fullPageDotsSel).hide();
		} else if ($($cache.fullPageDotsSel).is(':hidden')) {
			$($cache.fullPageDotsSel).show();
		}
	}

	function staticProductShow() {
		var $this = $(this);
		$this.find($cache.assetImgSel).addClass($cache.hHidden);
		$this.find($cache.assetImgLinkSel).addClass($cache.hShowInline);
	}

	function staticProductHide() {
		var $this = $(this);
		$this.find($cache.assetImgSel).removeClass($cache.hHidden);
		$this.find($cache.assetImgLinkSel).removeClass($cache.hShowInline);
	}

	function staticProductHideAll() {
		$cache.assetWrapper.find($cache.assetImgSel).removeClass($cache.hHidden);
		$cache.assetWrapper.find($cache.assetImgLinkSel).removeClass($cache.hShowInline);
	}

	function staticProductHideExceptTarget() {
		var $this = $(this);
		var siblingElements = $this.siblings();

		siblingElements.find($cache.assetImgSel).removeClass($cache.hHidden);
		siblingElements.find($cache.assetImgLinkSel).removeClass($cache.hShowInline);
	}

	function attachParallaxEffect() {
		$($cache.parallaxTypeElement).each(function() {
			var $this = $(this);
			var speed = $this.data('speed') || configs.defaultParallaxSpeed;
			var topOffset = $this.offset().top;

			$cache.window.on('scroll', function() {
				// checking if element in view
				if ((($cache.window.scrollTop() + $cache.window.height()) > topOffset)
					&& ((topOffset + $this.height()) > $cache.window.scrollTop())) {
					var yCoord = -($cache.window.scrollTop() * speed);
					$this.css({ transform: 'translateY(' + yCoord + 'px)' });
				}
			});
		});
	}

	function initProductEvents() {
		// in case js is enabled - quickview popup is opened from global.quickview.js
		// if not - following the link
		$($cache.hpQuickViewLinkSel).on('click', function(e) {
			e.preventDefault();
		});

		$($cache.hpWishlistLinkSel).on('click', function(e) {
			e.preventDefault();
		});
	}

	function initCarouselEvents() {
		$cache.$owlCarousels.each(function() {
			var $carousel = $(this);
			var settings = $carousel.data('settings');

			if (settings.numberOfSlide === true && settings.nav === true) {
				setDataAttributeNumberSlide($carousel.find($cache.owlDotsSel), 'data-current-slide', 1);
				setDataAttributeNumberSlide($carousel.find($cache.owlDotsSel), 'data-total-slide', $carousel.find($cache.owlSlideItemSel).length);

				$carousel.on('changed.owl.carousel', function(e) {
					setDataAttributeNumberSlide($carousel.find($cache.owlDotsSel), 'data-current-slide', e.relatedTarget.relative(e.relatedTarget.current()) + 1);
				});
			}

			var pointerTouchHandler = function() {
				var $dots = $carousel.find($cache.owlDotSel);
				// eslint-disable-next-line no-undef
				if (Modernizr.touchevents) {
					if (settings.stopOnTap === true) {
						$dots.on('touchstart', function() {
							$carousel.trigger('stop.owl.autoplay');
						});
					}
				} else {
					if (settings.changeOnOver === true) {
						$dots.mouseover(function() {
							$carousel.trigger('to.owl.carousel', $dots.index(this));
						});
					}
					if (settings.pauseWhileOver === true) {
						$dots.mouseenter(function() {
							$carousel.trigger('mouseover.owl.autoplay');
						});
						$dots.mouseleave(function() {
							$carousel.trigger('mouseleave.owl.autoplay');
						});
					}
				}

				$dots.on('click touchend', function(e) {
					const $link = $(this).find('a');
					if ($link.length) {
						e.preventDefault();
						const target = $link.attr('target');
						const a = document.createElement('a');
						a.target = '_self';
						if (target) {
							a.target = target;
						}
						a.href = $link.attr('href');
						a.click();
					}
				});
			};

			if ($carousel.hasClass($cache.owlLoadedClass)) {
				pointerTouchHandler();

				if ($carousel[0].closest($cache.owlCarouselMainPDP)) {
					$carousel.find(`.${$cache.owlItemActiveClass}`).last().next().addClass($cache.owlItemActiveClass);
				}
			} else {
				$carousel.on('initialized.owl.carousel', pointerTouchHandler);
			}

			$carousel.on('changed.owl.carousel', function(event) {
				if ($carousel[0].closest($cache.owlCarouselMainPDP)) {
					const item = $carousel.find($cache.owlItemDefaultClass)[event.item.index + 1];

					app.util.throttle(function() {
						$(item).next().addClass($cache.owlItemActiveClass);
					});
				}
			});
		});

		if ($cache.productCarousel.length) {
			$cache.productCarousel.on('owl.afterUpdate', function() {
				app.wishlist.init();
				initProductEvents();
			});
		}

		$cache.carouselWithThumbs.on('refreshed.owl.carousel', function() {
			var currentElement = $($cache.carouselSignatures + '[data-animation]');
			var animationClass;
			if (currentElement.length) {
				animationClass = currentElement.data('animation');
				currentElement.addClass(animationClass);
				attachTextScrollEffect(currentElement);
			}
		})
			.on('changed.owl.carousel', function(evt) {
				var currentElement = $($cache.carouselItem);
				var currentClonedElement = $('.' + $cache.clonedItem);
				currentElement.find($cache.carouselSignatures + '[data-animation]').removeAttr('style');
				currentClonedElement.find($cache.carouselSignatures + '[data-animation]').removeAttr('style');
				$cache.window.off('.topBanner');
				manageTextAnimation(currentElement, 'removeClass');
				manageTextAnimation(currentClonedElement, 'removeClass');
				changedElement = currentElement.eq(calculateCarouselItem(evt));
			})
			.on('translated.owl.carousel', function() {
				var clonedElement = $(this).find($cache.carouselActiveItem);
				var selectedItem = (clonedElement.parent().hasClass($cache.clonedItem)) ? clonedElement : changedElement;
				if (selectedItem != null && selectedItem.length) {
					var textBlock = manageTextAnimation(selectedItem, 'addClass');
					attachTextScrollEffect(textBlock);
				}
			});
	}

	function manageTextAnimation(element, action) {
		if (!action || (action !== 'removeClass' && action !== 'addClass')) {
			return;
		}
		var thumbsTextBlock = element.find($cache.carouselSignatures + '[data-animation]');
		var animationClass;
		if (thumbsTextBlock.length) {
			animationClass = thumbsTextBlock.data('animation');
			thumbsTextBlock[action](animationClass);
			return thumbsTextBlock;
		}
	}

	function attachTextScrollEffect(element) {
		if (!element) return;
		var elementHeight = element.outerHeight();
		$cache.window.on('scroll.topBanner', function() {
			element.removeClass(element.data('animation'));
			var	scrollTop = $(this).scrollTop();
			var styleObj = {};
			var calcOpacity;
			var offset;
			var gradientStyle;
			var calcPosition;
			var max;
			var min;

			offset = elementHeight * configs.scrollDelta;
			calcOpacity = 1 - (scrollTop / offset);

			element.css({ opacity: calcOpacity });

			if (Number(calcOpacity) > 1) {
				element.css({ opacity: 1 });
			} else if (Number(calcOpacity) < 0) {
				element.css({ opacity: 0 });
			}

			gradientStyle = defineGradientStyle(element);

			if (gradientStyle) {
				max = configs.signGradientMap[gradientStyle].max;
				min = configs.signGradientMap[gradientStyle].min;
				calcPosition = (1 - (scrollTop / offset)) * (max - min) + min;
				styleObj[gradientStyle] = calcPosition + '%';
				element.css(styleObj);

				if ((max > min && calcPosition > max) || (max < min && calcPosition < max)) {
					styleObj[gradientStyle] = max + '%';
					element.css(styleObj);
				} else if ((max > min && calcPosition < min) || (max < min && calcPosition > min)) {
					styleObj[gradientStyle] = min + '%';
					element.css(styleObj);
				}
			}
		});
	}

	function defineGradientStyle(element) {
		if (!element || !element.length) {
			return;
		}
		var elementClass = element.attr('class');
		var gradientStyle;

		if (elementClass.indexOf($cache.signCenterBottomSel + ' ') !== -1 || elementClass.indexOf($cache.signRightBottomSel + ' ') !== -1) {
			gradientStyle = 'bottom';
		} else if (elementClass.indexOf($cache.signCenterSel + ' ') !== -1 || elementClass.indexOf($cache.signRightSel + ' ') !== -1) {
			gradientStyle = 'top';
		}

		return gradientStyle;
	}

	function calculateCarouselItem(evt) {
		if (!evt) {
			return;
		}
		// eslint-disable-next-line no-underscore-dangle
		var currentIndex = (evt.item.index + 1) - evt.relatedTarget._clones.length / 2;
		var allItems = evt.item.count;
		if (currentIndex > allItems || currentIndex === 0) {
			currentIndex = allItems - (currentIndex % allItems);
		}
		return currentIndex - 1;
	}

	function initHomepageInstagramBanner() {
		var instafeedCreds = app.preferences.services.instagram.homepage;
		var options = {
			get: instafeedCreds.get,
			userId: Number(instafeedCreds.userId),
			accessToken: instafeedCreds.accessToken,
			clientId: instafeedCreds.clientId,
			limit: instafeedCreds.limit,
			resolution: instafeedCreds.resolution,
			template: configs.template
		};
		if (options.accessToken) {
			app.instafeed.init(options);
		}
	}

	function reinitTabletInstagramStaticBanner() {
		// change QV to PDP link for static banner
		var pdpLinks = $cache.assetWrapper.find($cache.assetImgLinkSel);
		if (configs.currentDevice === 'tablet' && pdpLinks.length) {
			pdpLinks.each(function() {
				var pdpLink = $(this);
				pdpLink.removeClass($cache.hpQuickViewLinkSel.replace(/\./g, ''))
					.data('url', '');
				if (pdpLink.data('pdp')) {
					pdpLink.attr('href', pdpLink.data('pdp'));
				}
			});
		}
	}

	function initShowRoomPopupEvents() {
		$cache.showroom.countryBtnEl.on('click', function() {
			var fancyboxContent = $(this).data('content');
			if (fancyboxContent) {
				app.fancybox.open($(fancyboxContent).html(), {
					wrapCSS: 'b-showroom-popup b-modal-wrapper'
				});
			}
		});
	}

	app.components = app.components || {};
	app.components.storefront = app.components.storefront || {};
	app.components.storefront.custom = {
		init: function() {
			initializeCache();
			initializeDOM();
			initializeEvents();
		}
	};
})(window.app = window.app || {}, jQuery);
