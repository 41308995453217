(function(app, $) {
	var $cache = {};
	var configs = {};

	/**
	 * @function
	 * @description Init cache
	 */
	function initializeCache() {
		$cache = {
			document : $(document),
			rootElements: $('html, body'),
			headerSel : 'header',
			body : $('body'),
			searchPhraseInput : $(".js-simple_search_phrase"),
			clearSearchPhraseInput: $('.js-search-input_clear'),
			searchInput : $('.js-quicksearch'),
			suggestInput : $(".js-simple_search_suggest_phrase"),
			resultContainer : $('.js-quicksearch_result_container'),
			suggestInputValue : "",
			searchIcon : $('.js-search-icon'),
			searchButtonSel: '.js-simple_search_submit_button',
			searchCategoryButtons: $('.js-min_search .js-simple_search_category_button'),
			simpleSearchSuggestions: $('.js-simple_search_suggestions'),
			searchCategoryButtonsBlock: $('.js-simple_search_cat_btn_block'),
			hiddenClass: 'h-hidden',
			searchCategoryButtonsActiveClass: 'active',
			searchCategoryButtonsClickedClass: 'clicked',
			searchCategoryIdInputHidden: $('.js-simple_search_category_id'),
			searchCategoryToggler: $('.js-simple_search-category_toggler'),
			minimizedClass: 'h-minimized',
			searchCategoryTogglerSel: '.js-simple_search-category_toggler',
			minSearchSel: 'form.js-min_search',
			minSearchForm: $('form.js-min_search'),
			searchSuggestionsActive: 'm-search_suggestions--active'
		};
	}

	function handleSearchCategoryChange($categoryButton) {
		$cache.searchCategoryButtons.removeClass($cache.searchCategoryButtonsClickedClass);
		$categoryButton.addClass($cache.searchCategoryButtonsClickedClass);
		if (!$categoryButton.hasClass($cache.searchCategoryButtonsActiveClass) || app.util.getConfig('searchForm.genderSearchCTA') === 'submit') {
			$cache.searchCategoryButtons.removeClass($cache.searchCategoryButtonsActiveClass);
			$categoryButton.addClass($cache.searchCategoryButtonsActiveClass);

			if (app.util.getConfig('searchGender.saveCookie') || !app.device.isMobileUserAgent()) {
				$.cookie(app.preferences.simpleSearchCategoryCookieName, $categoryButton.val(), { path: '/' });
			}

			$cache.searchCategoryIdInputHidden.val($categoryButton.val());
			$cache.searchPhraseInput.trigger('simple.search.category.change');
		}
	}

	/**
	 * @function
	 * @description Init DOM events
	 */
	function initializeEvents() {

		$cache.document.on('click', $cache.searchButtonSel, function(e) {
			e.preventDefault();
			var value = $cache.searchPhraseInput.text();
			if ( value.length ) {
				$cache.searchInput.val(value);
				$cache.searchInput.closest($cache.minSearchSel).trigger("submit");
			
			}
		});
		
		$cache.searchPhraseInput.on("keypress", function(e){
			switch (e.which) {
				case 13:
					e.preventDefault();
					var $this = $(this); 
					var value = $this.text();
					if ( value.length ) {
						$cache.searchInput.val(value);
						
						if(app.util.getConfig('searchGender.submitOnReturn') || !app.device.isMobileUserAgent()){
							$cache.searchInput.closest($cache.minSearchSel).trigger("submit");
						}
					
					}
					
					if(app.util.getConfig('searchGender.blurOnReturn') && app.device.isMobileUserAgent()){
						$this.blur();
					}
					
					break;
				default:
					if(!$(this).hasClass('active')){
						$(this).addClass('active');
					}

					var suggest = $cache.suggestInput.html();
					if(suggest !== ""){
						$cache.suggestInputValue = suggest; 
						$cache.suggestInput.html(suggest.substring(1));
					} else {
						$cache.suggestInput.empty();
					}
					break;
			}
		});
		// event listener for start search after press "Enter" button
		$cache.searchCategoryButtons.on("keypress", function(e){
			if (e.which == 13) {
				e.preventDefault();
				var value = $cache.searchPhraseInput.text();
				if ( value.length ) {
					$cache.searchInput.val(value);

					if (app.util.getConfig('searchGender.refineOnReturn')) {
						handleSearchCategoryChange($(this));
					} else if (app.util.getConfig('searchGender.submitOnReturn') || !app.device.isMobileUserAgent()) {
						$cache.searchInput.closest($cache.minSearchSel).trigger('submit');
					}
				}
			}
		});
		
		$cache.searchPhraseInput.on("keyup simple.search.category.change", function(e){
			var $this = $(this),
				value = $.trim($this.text());
			
			if (e.type === "keyup") {
				$cache.searchCategoryButtons.removeClass($cache.searchCategoryButtonsClickedClass);
				$cache.searchCategoryButtons.filter($cache.searchCategoryButtonsActiveClass).data('redirect', null);
			}
			
			if (value[value.length - 1] !== $cache.suggestInputValue[0]){
				$cache.suggestInput.empty();
				$cache.suggestInputValue = "";
			}

			if (configs.handlers.searchPhraseInputChange) {
				configs.handlers.searchPhraseInputChange($cache, value);
			}

			if (value.length >= app.preferences.simplesearchTermLength) {
				$cache.searchInput.val(value);
				$cache.searchInput.trigger(e.type === "keyup" ? e : "keyup");
			} else {
				$cache.suggestInput.empty();
				$cache.resultContainer.empty().addClass($cache.minimizedClass);
				$cache.rootElements.removeClass($cache.searchSuggestionsActive);

				$cache.document.trigger('simple.search.body.padding.changed');
				
				var globalConfig = app.componentsconfig;
				if(app.device.isMobileUserAgent()){
					globalConfig = globalConfig['mobile'];
				}
				globalConfig = globalConfig.global.global.components;
				var simpleSearchConfig = globalConfig['global.simplesearch'];
				
				if (simpleSearchConfig && simpleSearchConfig.enabled !== false && !simpleSearchConfig.disableBodyPaddingTop) {
					// for correct calculation it should be initialized after call $cache.suggestInput.empty()
					var bodyPaddingTop = $( $cache.headerSel ).height();

					// it's decrease padding after result had been shown
					$cache.body.animate({"padding-top": bodyPaddingTop + "px"}, 0);
				}
			}
			// Android 5.1 chrome fix
			var regex = new RegExp(/<br>/);
			if ( regex.test(value) ) {
				$this.html( value.replace(regex, '') );
				$cache.searchInput.closest($cache.minSearchSel).trigger("submit");
			}
		});
		//event listener for focusing on search input when click on search icon
		$cache.searchIcon.on("click", function(){
			$cache.searchPhraseInput.focus();
		});
		
		//Focus out from search field to remove native keyboard screen from screen on mobile.
		$cache.document.on("toggle.hideall", function(){
			$cache.searchPhraseInput.blur();
		});
		
		$cache.searchCategoryButtons.on("click", function(e) {
			var $this = $(this);
			handleSearchCategoryChange($this);
			
			if (app.util.getConfig('searchGender.submitOnGenderClick')) {
				if ($cache.minSearchForm.valid()) {
					$cache.minSearchForm.submit();
				} else {
					setTimeout(function(){
						$this.removeClass($cache.searchCategoryButtonsActiveClass);
					}, 500);
				}
			}
		});
		
		$cache.document.on('click', $cache.searchCategoryTogglerSel, function(){
			$(this).toggleClass('h-opened');
			$cache.searchCategoryButtonsBlock.toggleClass('h-show');

		});
		
		$cache.searchPhraseInput.on('simple.search.category.change',function(){
			if ($cache.searchCategoryToggler.length > 0) {
				var activeCategoryName = $cache.searchCategoryButtons.filter('.active').html();
				$cache.searchCategoryToggler.html(activeCategoryName);
			}
		});
		
		$cache.minSearchForm.on('submit', function() {
			if (!$(this).valid()) return false;
		});

		if ($cache.minSearchForm.length) {
			$cache.minSearchForm.validate().settings.ignore = [];
		}

		$cache.clearSearchPhraseInput.on('click keydown', function(e) {
			if (e.type === 'keydown' && e.which !== 13 && e.which !== 32) {
				return;
			}
			e.preventDefault();
			$cache.searchPhraseInput
				.empty()
				.focus()
				.trigger('simple.search.category.change');
			$cache.rootElements.removeClass($cache.searchSuggestionsActive);
		});
	}
	
	function setActiveSearchCategoryButton(){
		var catCookieName = app.preferences.simpleSearchCategoryCookieName,
			activeCategory = catCookieName && $.cookie(catCookieName) || app.preferences.simpleSearchDefaultCategoryId;
		if(typeof activeCategory === 'string') {
			$cache.searchCategoryButtons.filter("[value='" + activeCategory + "']").addClass($cache.searchCategoryButtonsActiveClass);
			$cache.searchCategoryIdInputHidden.val(activeCategory);
		}
	}

	function initializeConfigs(params) {
		configs.handlers = {};
		if (params) {
			configs = $.extend(true, configs, params);
		}
	}

	/**
	 * @namespace app.components.simplesearch.gender public object
	 */
	app.components = app.components || {};
	app.components.simplesearch = app.components.simplesearch || {};
	app.components.simplesearch.gender = {
		init: function(params) {
			initializeConfigs(params);
			initializeCache();
			initializeEvents();
			
			if((app.util.getConfig('searchGender.saveCookie') || !app.device.isMobileUserAgent()) && app.util.getConfig('searchGender.defaultActiveButton')){
				setActiveSearchCategoryButton();
			}
		}
	};
}(window.app = window.app || {}, jQuery));
