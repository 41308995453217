import ImgLazyLoad from '../libs/lazyload';

var lazyLoad = new ImgLazyLoad({
	cancel_on_exit: false
});

document.addEventListener('lazyload-reinit', function() {
	lazyLoad.update();
});

document.addEventListener('lazyload-loadAll', function() {
	lazyLoad.loadAll();
});

document.addEventListener('DOMContentLoaded', function() {
	document.dispatchEvent(new CustomEvent('lazyload-reinit'));

	(function($) {
		$(document).on('recommendations.loaded wishlist.uploaded product.added nextpage-loaded wishlist.added wishlist.removed last.visited.loaded minicart.load minicart.show minicart.afterload minicart.product.removed dialog.showed update.header changeVariation.changed wishlistblock.removed quickview.opened product.variation.reloaded fancybox.closed', function() {
			document.dispatchEvent(new CustomEvent('lazyload-reinit'));
		});
	})(jQuery);
});
